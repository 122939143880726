import axios from "@/services/api";

const state = {
  UnitOrganitaions: [],
  OUs: [],
  UnitOrganitaion: null,
  loading: false,
};
const getters = {
  OUs: (state) => state.OUs,
  UnitOrganitaions: (state) => state.UnitOrganitaions,
  UnitOrganitaion: (state) => state.UnitOrganitaion,
  loading: (state) => state.loading,
};
const actions = {
  fetchUnitOrganitaions({ commit }, payload) {
    let { bSettings } = payload;
    commit("request");
    return new Promise((resolve, reject) => {
      axios
        .get(`/OrganizationalUnit/UserBelongs`, {
          params: { settings: bSettings },
        })
        .then((response) => {
          // handle success
          var data = [];
          response.data.organizationalUnits.forEach((ou) => {
            data.push({ value: ou.idOU, text: ou.nameOrganizationalUnit });
          });
          commit("setUnitOrganitaions", data);
          commit("requested");
          resolve(data);
        })
        .catch((error) => {
          // handle error
          console.log(error);
          commit("requested");
          reject(error);
        });
    });
  },
  setUnitOrganitaion: ({ commit }, payload) => {
    let { idOU } = payload;
    commit("setUnitOrganitaion", idOU);
  },
  getOUs({ commit }, payload) {
    const { enabled } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/OrganizationalUnit/ListOU`, {
          params: { enabled },
        })
        .then(
          (response) => {
            commit("setOUs", response.data);
            resolve(response);
          },
          (error) => {
            commit("setOUs", []);
            reject(error);
          },
        );
    });
  },
  AddOU({ commit }, payload) {
    const { nameOrganizationalUnit, emailAddress } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`/OrganizationalUnit/CreateOU`, {
          nameOrganizationalUnit,
          emailAddress,
        })
        .then(
          (response) => {
            commit("addOU", response.data.object);
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  DeleteOU({ commit }, payload) {
    const { idOrganizationalUnit } = payload;
    return new Promise((resolve, reject) => {
      axios.delete(`/OrganizationalUnit/DeleteOU/${idOrganizationalUnit}`).then(
        (response) => {
          commit("deleteOU", idOrganizationalUnit);
          resolve(response.data);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
  UpdateOU({ commit }, payload) {
    const { idOrganizationalUnit, nameOrganizationalUnit, emailAddress } =
      payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/OrganizationalUnit/UpdateOU`, {
          idOrganizationalUnit,
          nameOrganizationalUnit,
          emailAddress,
        })
        .then(
          (response) => {
            commit("updateOU", {
              idOrganizationalUnit,
              nameOrganizationalUnit,
              emailAddress,
            });
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  ActiveOU({ commit }, payload) {
    const { idOrganizationalUnit } = payload;
    return new Promise((resolve, reject) => {
      axios.put(`/OrganizationalUnit/ActiveOU/${idOrganizationalUnit}`).then(
        (response) => {
          commit("deleteOU", idOrganizationalUnit);
          resolve(response.data);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
};
const mutations = {
  setUnitOrganitaions: (state, unitOrganitaions) => {
    state.UnitOrganitaions = unitOrganitaions;
  },
  setUnitOrganitaion: (state, unitOrganitaion) => {
    state.UnitOrganitaion = unitOrganitaion;
  },
  request: (state) => {
    state.loading = true;
  },
  requested: (state) => {
    state.loading = false;
  },
  setOUs: (state, OUs) => {
    state.OUs = OUs;
  },
  addOU: (state, OU) => {
    state.OUs.push(OU);
  },
  deleteOU: (state, idOrganizationalUnit) => {
    const index = state.OUs.findIndex(
      (x) => x.idOrganizationalUnit == idOrganizationalUnit,
    );
    state.OUs.splice(index, 1);
  },
  updateOU: (
    state,
    { idOrganizationalUnit, nameOrganizationalUnit, emailAddress },
  ) => {
    const index = state.OUs.findIndex(
      (x) => x.idOrganizationalUnit == idOrganizationalUnit,
    );
    state.OUs[index].idOrganizationalUnit = idOrganizationalUnit;
    state.OUs[index].nameOrganizationalUnit = nameOrganizationalUnit;
    state.OUs[index].emailAddress = emailAddress;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
