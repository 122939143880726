<template>
  <div class="d-flex justify-content-center mb-2 mt-2">
    <b-spinner
      variant="primary"
      class="mx-2"
      style="width: 1.5rem; height: 1.5rem"
      type="grow"
    />
    <b-spinner
      variant="primary"
      class="mx-2"
      style="width: 1.5rem; height: 1.5rem"
      type="grow"
    />
    <b-spinner
      variant="primary"
      class="mx-2"
      style="width: 1.5rem; height: 1.5rem"
      type="grow"
    />
  </div>
</template>
