<template>
  <div class="login-view bg-healex-gradient">
    <b-container>
      <div class="box text-center">
        <b-card class="inner shadow title-noFound">
          <span class="code-error-title">404</span><br />
          <span class="code-error-subtitle">{{
            $t("views.pageNofound.Title")
          }}</span
          ><br />
          <span class="code-error-subtitle2">{{
            $t("views.pageNofound.subtitle")
          }}</span
          ><br />
          <b-button @click="$router.push('/')" variant="info">{{
            $t("views.pageNofound.goToHome")
          }}</b-button>
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.login-view {
  position: relative;
  width: 100%;
  height: 100%;
  background: #dedede;
}

.login-view .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-view .box .inner {
  position: relative;
  width: 500px;
  max-width: 100%;
}

.login-view .helex-log {
  display: block;
  max-width: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}
.title-noFound {
  font-size: 3rem;
  font-weight: bold;
  color: #808080;
}
.code-error-title {
  font-size: 9rem;
  font-weight: bold;
}
.code-error-subtitle {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 30px;
}
.code-error-subtitle2 {
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 30px;
}
</style>
