<template>
  <b-modal
    id="new-password-modal"
    ref="my-modal"
    :title="$t('password.title')"
    @ok="handleOk"
    @show="onShow"
    @hidden="resetModal"
  >
    <div>
      <b-alert show variant="info">
        <p>{{ $t("password.text") }}</p>
        <hr />
        <ul class="mb-0">
          <li>{{ $t("password.uppercaseRequired") }}</li>
          <li>{{ $t("password.lowercaseRequired") }}</li>
          <li>{{ $t("password.numberRequired") }}</li>
          <li>
            {{ $t("password.symbolRequired") }}
            <b
              >&#33; &#64; &#35; &#36; &#37; &#94; &#38; &#42; &#95; &#61; &#43;
              &#45;</b
            >
          </li>
          <li>{{ $t("password.charactersRequired") }}</li>
        </ul>
      </b-alert>
    </div>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="NewPasswordState"
        :label="$t('password.newPassword')"
        label-for="NewPassword"
        :invalid-feedback="NewPasswordfeedback"
      >
        <b-form-input
          ref="NewPassword"
          id="NewPassword"
          type="password"
          v-model="NewPassword"
          :state="NewPasswordState"
          required
          minlength="8"
          maxlength="12"
          pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :state="RepeatPasswordState"
        :label="$t('password.repeatPassword')"
        label-for="RepeatPassword"
        :invalid-feedback="RepeatPasswordfeedback"
      >
        <b-form-input
          ref="RepeatPassword"
          id="RepeatPassword"
          type="password"
          v-model="RepeatPassword"
          :state="RepeatPasswordState"
          required
          minlength="8"
          maxlength="12"
          pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}"
        ></b-form-input>
      </b-form-group>
    </form>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="handleOk"
          >{{ $t("general.ok") }}</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    show() {
      this.$refs["my-modal"].show();
    },
    RepeatPassword(newValue) {
      const regx =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/g;

      if (newValue.length < 8 || newValue.length > 12) {
        this.RepeatPasswordfeedback = this.$t("password.lengthError");
        this.RepeatPasswordState = false;
        return;
      }

      if (!regx.test(newValue)) {
        this.RepeatPasswordfeedback = this.$t("password.patternError");
        this.RepeatPasswordState = false;
        return;
      }
      this.RepeatPasswordState = true;
      return;
    },
    NewPassword(newValue) {
      const regx =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/g;

      if (newValue.length < 8 || newValue.length > 12) {
        this.NewPasswordfeedback = this.$t("password.lengthError");
        this.NewPasswordState = false;
        return;
      }

      if (!regx.test(newValue)) {
        this.NewPasswordfeedback = this.$t("password.patternError");
        this.NewPasswordState = false;
        return;
      }
      this.NewPasswordState = true;
      return;
    },
  },
  data() {
    return {
      RepeatPassword: "",
      RepeatPasswordState: null,
      RepeatPasswordfeedback: "",
      NewPassword: "",
      NewPasswordState: null,
      NewPasswordfeedback: "",
    };
  },
  methods: {
    ...mapActions({
      getFeatures: "GET_FEATURES",
      finishAllSessions: "FINISHALLSESSIONS",
    }),
    makeToast(message) {
      this.$bvToast.toast(message, {
        title: this.$t("password.title"),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    checkFormValidity() {
      this.NewPasswordState = this.$refs.NewPassword.checkValidity();
      this.RepeatPasswordState = this.$refs.RepeatPassword.checkValidity();
      if (!this.NewPasswordState) {
        return false;
      }
      if (!this.RepeatPasswordState) {
        return false;
      }
      return this.NewPassword === this.RepeatPassword;
    },
    resetModal() {
      this.RepeatPassword = "";
      this.RepeatPasswordState = null;
      this.NewPassword = "";
      this.NewPasswordState = null;
    },
    onShow() {
      this.resetModal();
      this.finishAllSessions({ api: this.$api });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      const that = this;
      this.$api
        .ChangePassword({
          userName: localStorage.getItem("healex-userName"),
          password: this.NewPassword,
        })
        .then((resp) => {
          localStorage.setItem("healex-passwordChanged", true);
          // Hide the modal manually
          that.makeToast(resp.data.message);
          that.$nextTick(() => {
            this.$refs["my-modal"].hide();
          });
          that.$router.push("/");

          this.getFeatures({ api: this.$api });
        })
        .catch((error) => {
          console.log("Change Password error: ", error);
        });
    },
  },
};
</script>

<style></style>
