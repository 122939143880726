<template>
  <b-modal
    id="data-privacy-modal"
    :title="$t('dataPrivacy.header')"
    title-tag="h2"
    size="xl"
    no-stacking
    ok-only
    no-close-on-backdrop
    scrollable
  >
    <div class="mx-3">
      <p align="justify">{{ $t("dataPrivacy.p1") }}</p>
      <p align="justify">{{ $t("dataPrivacy.p2") }}</p>
      <p align="justify">{{ $t("dataPrivacy.p3") }}</p>
      <h3>{{ $t("dataPrivacy.section1") }}</h3>
      <p align="justify">{{ $t("dataPrivacy.s1p1") }}</p>
      <p align="justify">{{ $t("dataPrivacy.s1p2") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle1") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st1p1") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle2") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st2p1") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle3") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st3p1") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle4") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st4p1") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle5") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st5p1") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle6") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st6p1") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle7") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st7p1") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle8") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st8p1") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle9") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st9p1") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle10") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st10p1") }}</p>
      <h4>{{ $t("dataPrivacy.s1subtitle11") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s1st11p1") }}</p>
      <h3>{{ $t("dataPrivacy.section2") }}</h3>
      <p align="justify">{{ $t("dataPrivacy.s2p1") }}</p>
      <p align="justify">
        {{ $t("dataPrivacy.direction1") }} <br />
        {{ $t("dataPrivacy.direction2") }} <br />
        {{ $t("dataPrivacy.direction3") }}
      </p>
      <p align="justify">{{ $t("dataPrivacy.phone") }}</p>
      <p align="justify">{{ $t("dataPrivacy.email") }}</p>
      <p align="justify">{{ $t("dataPrivacy.website") }}</p>
      <h3>{{ $t("dataPrivacy.section3") }}</h3>
      <p align="justify">
        {{ $t("dataPrivacy.s3p1") }} <br />
        {{ $t("dataPrivacy.s3p2") }}
      </p>
      <p align="justify">{{ $t("dataPrivacy.s3p3") }}</p>
      <p align="justify">{{ $t("dataPrivacy.s3p4") }}</p>
      <h3>{{ $t("dataPrivacy.section4") }}</h3>
      <p align="justify">
        {{ $t("dataPrivacy.s4p1d1") }} <b> {{ $t("dataPrivacy.s4p1d2") }} </b>
        {{ $t("dataPrivacy.s4p1d3") }} <b> {{ $t("dataPrivacy.s4p1d4") }} </b>
        {{ $t("dataPrivacy.s4p1d5") }}
      </p>
      <p align="justify">
        {{ $t("dataPrivacy.s4p2d1") }} <b> {{ $t("dataPrivacy.s4p2d2") }} </b>
        {{ $t("dataPrivacy.s4p2d3") }}
      </p>
      <ul>
        <li>{{ $t("dataPrivacy.s4p3item1") }}</li>
        <li>{{ $t("dataPrivacy.s4p3item2") }}</li>
        <li>{{ $t("dataPrivacy.s4p3item3") }}</li>
        <li>{{ $t("dataPrivacy.s4p3item4") }}</li>
        <li>{{ $t("dataPrivacy.s4p3item5") }}</li>
      </ul>
      <p align="justify">{{ $t("dataPrivacy.s4p4") }}</p>
      <h4>{{ $t("dataPrivacy.s4subtitle1") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s4st1p1") }}</p>
      <h4>{{ $t("dataPrivacy.s4subtitle2") }}</h4>
      <p align="justify">
        {{ $t("dataPrivacy.s4st2p1") }} <br />
        <b> {{ $t("dataPrivacy.s4st2p2d1") }}</b>
        {{ $t("dataPrivacy.s4st2p2d2") }}
      </p>
      <h4>{{ $t("dataPrivacy.s4subtitle3") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s4st3p1") }}</p>
      <h4>{{ $t("dataPrivacy.s4subtitle4") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s4st4p1") }}</p>
      <h4>{{ $t("dataPrivacy.s4subtitle5") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s4st5p1") }}</p>
      <ul>
        <li>{{ $t("dataPrivacy.s4st5p2item1") }}</li>
        <li>{{ $t("dataPrivacy.s4st5p2item2") }}</li>
        <li>{{ $t("dataPrivacy.s4st5p2item3") }}</li>
        <li>{{ $t("dataPrivacy.s4st5p2item4") }}</li>
        <li>{{ $t("dataPrivacy.s4st5p2item5") }}</li>
        <li>{{ $t("dataPrivacy.s4st5p2item6") }}</li>
        <li>{{ $t("dataPrivacy.s4st5p2item7") }}</li>
        <li>{{ $t("dataPrivacy.s4st5p2item8") }}</li>
      </ul>
      <p align="justify">{{ $t("dataPrivacy.s4st5p3") }}</p>
      <p align="justify">{{ $t("dataPrivacy.s4st5p4") }}</p>
      <h4>{{ $t("dataPrivacy.s4subtitle6") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s4st6p1") }}</p>
      <p align="justify">
        {{ $t("dataPrivacy.s4st6p2") }}<br />
        <b>{{ $t("dataPrivacy.s4st6p3") }}</b>
      </p>
      <h4>{{ $t("dataPrivacy.s4subtitle7") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s4st7p1") }}</p>
      <ul>
        <li>{{ $t("dataPrivacy.s4st7p2item1") }}</li>
        <li>{{ $t("dataPrivacy.s4st7p2item2") }}</li>
        <li>{{ $t("dataPrivacy.s4st7p2item3") }}</li>
        <li>{{ $t("dataPrivacy.s4st7p2item4") }}</li>
        <li>{{ $t("dataPrivacy.s4st7p2item5") }}</li>
        <li>{{ $t("dataPrivacy.s4st7p2item6") }}</li>
      </ul>
      <p align="justify">{{ $t("dataPrivacy.s4st7p3") }}</p>
      <p align="justify">{{ $t("dataPrivacy.s4st7p4") }}</p>
      <h4>{{ $t("dataPrivacy.s4subtitle8") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s4st8p1") }}</p>
      <ul>
        <li>{{ $t("dataPrivacy.s4st8p2item1") }}</li>
        <li>{{ $t("dataPrivacy.s4st8p2item2") }}</li>
        <li>{{ $t("dataPrivacy.s4st8p2item3") }}</li>
        <li>{{ $t("dataPrivacy.s4st8p2item4") }}</li>
      </ul>
      <p align="justify">{{ $t("dataPrivacy.s4st8p3") }}</p>
      <h4>{{ $t("dataPrivacy.s4subtitle9") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s4st9p1") }}</p>
      <p align="justify">{{ $t("dataPrivacy.s4st9p2") }}</p>
      <p align="justify">{{ $t("dataPrivacy.s4st9p3") }}</p>
      <h4>{{ $t("dataPrivacy.s4subtitle10") }}</h4>
      <p align="justify">{{ $t("dataPrivacy.s4st10p1") }}</p>
      <p align="justify">{{ $t("dataPrivacy.s4st10p2") }}</p>
      <p align="justify">{{ $t("dataPrivacy.s4st10p3") }}</p>
      <h3>{{ $t("dataPrivacy.section5") }}</h3>
      <p align="justify">{{ $t("dataPrivacy.s5p1") }}</p>
      <h3>{{ $t("dataPrivacy.section6") }}</h3>
      <p align="justify">
        {{ $t("dataPrivacy.s6p1") }} <br />
        {{ $t("dataPrivacy.s6p2") }} <br />
        {{ $t("dataPrivacy.s6p3") }}
      </p>
    </div>
  </b-modal>
</template>
