import axios from "@/services/api";

const state = {
  generalSummations: [],
};

const getters = {
  generalSummations: (state) => state.generalSummations,
};

const actions = {
  getGeneralSummation({ commit }, payload) {
    let { enabled } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/customer/general-summations`, {
          params: { enabled },
        })
        .then(
          (response) => {
            commit("setGeneralSummations", response.data);
            resolve(response);
          },
          (error) => {
            console.log("General summations: ", error);
            commit("setGeneralSummations", []);
            reject(error);
          },
        );
    });
  },
  addGeneralSummation({ commit }, payload) {
    const { generalSummationName, enabled } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`/general-summation`, {
          generalSummationName,
          enabled,
        })
        .then(
          (response) => {
            commit("addNewGeneralSummation", {
              idGeneralSummation: response.data.idGeneralSummation,
              generalSummationName: response.data.generalSummationName,
              enabled: response.data.enabled,
            });
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  updateGeneralSummationName({ commit }, payload) {
    const { idGeneralSummation, generalSummationName } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/general-summation/${idGeneralSummation}/name`, {
          generalSummationName,
        })
        .then(
          (response) => {
            commit("setGeneralSummationName");
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  updateGeneralSummationState({ commit }, payload) {
    const { idGeneralSummation, status } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/general-summation/${idGeneralSummation}/status`, {
          status: status,
        })
        .then(
          (response) => {
            commit("deleteGeneralSummation", idGeneralSummation);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
};
const mutations = {
  setGeneralSummations: (state, generalSummations) => {
    state.generalSummations = generalSummations;
  },

  addNewGeneralSummation: (state, newGeneralSummation) => {
    state.generalSummations.push(newGeneralSummation);
  },

  setGeneralSummationName: () => {},

  deleteGeneralSummation: (state, idGeneralSummation) => {
    state.generalSummations = state.generalSummations.filter(function (obj) {
      return obj.idGeneralSummation !== idGeneralSummation;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
