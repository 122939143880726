import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/custom.scss";
import i18n from "./i18n";
import { API } from "./services/api";
import OidcClient from "./services/auth";
import VueCookies from "vue-cookies";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import SimpleLoader from "@/components/general/simple-loader.vue";
import VueSimpleContextMenu from "vue-simple-context-menu";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import "./plugins/fontawesome";
import BackButton from "@/components/BackButton.vue";

const app = createApp(App);

const api = new API();
app.config.globalProperties.$oidcClient = new OidcClient();

const axiosPlugin = {
  install(app) {
    app.config.globalProperties.$api = api;
  },
};

app.use(store);
app.use(router);
app.use(i18n);
app.use(VueCookies);
app.use(axiosPlugin);
app.use(BootstrapVue);
app.use(IconsPlugin);

app.config.globalProperties.$api = api;
app.config.productionTip = false;

app.component("vue-simple-context-menu", VueSimpleContextMenu);
app.component("simple-loader", SimpleLoader);
app.component("back-button", BackButton);

app.mount("#app");
