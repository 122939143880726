import axios from "@/services/api";

const state = {
  comments: [],
};

const getters = {
  comments: (state) => state.comments,
};

const actions = {
  getCostCalculationComments({ commit }, payload) {
    let { idCostCalculation } = payload;
    return new Promise((resolve, reject) => {
      axios.get(`cost-calculations/${idCostCalculation}/comments`).then(
        (response) => {
          commit("setComments", response.data);
          resolve(response);
        },
        (error) => {
          commit("setComments", []);
          reject(error.response.data.text);
        },
      );
    });
  },
  postComment({ commit }, payload) {
    let { idCostCalculation, comment } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`cost-calculations/${idCostCalculation}/comments`, {
          comment,
        })
        .then(
          (response) => {
            commit("addComment", response.data);
            resolve(response.data.text);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  replyComment({ commit }, payload) {
    let { idCostCalculation, comment, previousComment } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`cost-calculations/${idCostCalculation}/comments`, {
          comment,
          previousComment,
        })
        .then(
          (response) => {
            commit("addChildComment", response.data);
            resolve(response.data.text);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  resolveComment({ commit }, payload) {
    let { idComment } = payload;
    return new Promise((resolve, reject) => {
      axios.put(`cost-calculations/comments/${idComment}`).then(
        (response) => {
          commit("resolveComment", idComment);
          resolve(response.data.text);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
  deleteComment({ commit }, payload) {
    const { idComment } = payload;
    return new Promise((resolve, reject) => {
      axios.delete(`cost-calculations/comments/${idComment}`).then(
        (response) => {
          resolve(response.data.text);
          commit("deleteComment", idComment);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
  deleteChildComment({ commit }, payload) {
    const { idComment, idParentComment } = payload;
    return new Promise((resolve, reject) => {
      axios.delete(`cost-calculations/comments/${idComment}`).then(
        (response) => {
          resolve(response.data.text);
          commit("deleteChildComment", {
            idParentComment,
            idComment,
          });
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
};

const mutations = {
  deleteComment: (state, idComment) => {
    state.comments = state.comments.filter((obj) => {
      return obj.idComment !== idComment;
    });
  },
  deleteChildComment: (state, { idParentComment, idComment }) => {
    const parentComment = state.comments.find(
      (comment) => comment.idComment === idParentComment,
    );
    if (parentComment) {
      parentComment.childrenComments = parentComment.childrenComments.filter(
        function (obj) {
          return obj.idComment !== idComment;
        },
      );
    }
  },
  addComment: (state, comment) => {
    comment.childrenComments = [];
    comment.isResolved = false;
    state.comments.unshift(comment);
  },
  addChildComment: (state, comment) => {
    const parentComment = state.comments.find(
      (parent) => parent.idComment === comment.previousComment,
    );
    if (parentComment) {
      parentComment.isResolved = false;
      parentComment.childrenComments.unshift(comment);
    }
  },
  resolveComment(state, idComment) {
    const resolvedComment = state.comments.find(
      (comment) => comment.idComment === idComment,
    );
    if (resolvedComment) {
      resolvedComment.isResolved = true;
    }
  },
  setComments: (state, comments) => {
    state.comments = comments;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
