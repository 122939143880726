<template>
  <b-btn @click="onClick" size="sm" variant="primary"
    ><b-icon-arrow-left
  /></b-btn>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$router.back();
    },
  },
};
</script>

<style></style>
