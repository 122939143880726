import axios from "@/services/api";

const state = {
  salaryList: [],
  error: false,
  loading: false,
  studyTypes: [],
  surcharges: [],
  surchargeTypes: [],
};

const getters = {
  SalaryList: (state) => state.salaryList,
  Error: (state) => state.error,
  Loading: (state) => state.loading,
  StudyTypes: (state) => state.studyTypes,
  Surcharges: (state) => state.surcharges,
  SurchargeTypes: (state) => state.surchargeTypes,
};

const actions = {
  getSalaryCategories({ commit }, { idSalary }) {
    return new Promise((resolve, reject) => {
      axios.get(`/categories/salary-type/${idSalary}`).then(
        (response) => {
          commit("setSalaryCategories");
          resolve(response.data);
        },
        (error) => {
          console.log("Geting salary info: ", error);
          commit("setSalaryCategories");
          reject(error);
        },
      );
    });
  },
  getSeniorityValues({ commit }, { idSalary, idCategory, duration }) {
    return new Promise((resolve, reject) => {
      const URL =
        `/seniority-values/salary-type/${idSalary}/category/${idCategory}` +
        (duration !== undefined ? `?projectDuration=${duration}` : "");
      axios.get(URL).then(
        (response) => {
          commit("setSeniorityValues");
          resolve(response.data);
        },
        (error) => {
          console.log("Geting salary info: ", error);
          commit("setSeniorityValues");
          reject(error);
        },
      );
    });
  },
  getSalaryTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/salaries/types?onlyEnabled=true`).then(
        (response) => {
          commit("setSalaryTypes", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Salary types error: ", error);
          commit("setSalaryTypes", []);
          reject(error);
        },
      );
    });
  },
  getSalary({ commit }, { idSalary, idCategory, idSeniority }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/salry-customer/salary-type/${idSalary}/category/${idCategory}/seniority/${idSeniority}`,
        )
        .then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            console.log("Geting salary info: ", error);
            commit("setError");
            reject(error);
          },
        );
    });
  },
};

const mutations = {
  setSalaryCategories: () => {},
  setSeniorityValues: () => {},
  setSalaryTypes(state, list) {
    state.salaryList = list;
  },
  setError(state) {
    state.error = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
