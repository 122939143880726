import axios from "@/services/api";
import { inject } from "vue";

const session = inject("$vsession");
const state = {
  caseInfo: {},
  template: {},
  salaryList: [],
  caseId: -1,
  loading: false,
  caseState: 0,
  currentEditor: null,
  studyTypes: [],
  surcharges: [],
  surchargeTypes: [],
  summaryFile: [],
  materialCosts: [],
  patients: 0,
  perPatient: false,
  duration: 0,
  subcaseName: "",
  caseName: "",
  allowPerPatients: null,
  hasChanges: false,
};

const getters = {
  subcaseFile: (state) => state.caseInfo,
  subcaseName: (state) => state.subcaseName,
  SummaryFile: (state) => state.summaryFile,
  templateFile: (state) => state.template,
  SalaryList: (state) => state.salaryList,
  IDCase: (state) => state.caseId,
  loading: (state) => state.loading,
  State: (state) => state.caseState,
  Editor: (state) => state.currentEditor,
  StudyTypes: (state) => state.studyTypes,
  Surcharges: (state) => state.surcharges,
  SurchargeTypes: (state) => state.surchargeTypes,
  materialCosts: (state) => state.materialCosts,
  Patients: (state) => state.patients,
  PerPatient: (state) => state.perPatient,
  Duration: (state) => state.duration,
  caseName: (state) => state.caseName,
  parameters: (state) => state.caseInfo.Parameters,
  allowPerPatients: (state) => state.allowPerPatients,
  hasChanges: (state) => state.hasChanges,
};

const actions = {
  GetSubcase({ commit }, payload) {
    let { idSubcase } = payload;
    commit("request");
    return new Promise((resolve, reject) => {
      axios
        .get(`/subcases/${idSubcase}`)
        .then((response) => {
          // handle success
          let file = JSON.parse(response.data.file);
          let summaryFile = JSON.parse(response.data.summaryFile);
          commit("setcase", file);
          commit("setState", response.data.idStatus);
          commit("setEditor", response.data.currentEditor);
          commit("setSummaryFile", summaryFile);
          commit("setPatients", response.data.patients);
          commit("setPerPatient", response.data.allowPerPatients);
          commit("setDuration", response.data.duration);
          commit("setName", response.data.name);
          commit("setCaseName", response.data.caseName);
          commit("setAllowPerPatients", response.data.allowPerPatients);
          commit("setMaterialCosts", response.data.materialCostItems);
          resolve(response.data);
        })
        .catch((error) => {
          // handle error
          console.log(error);
          reject(error.response);
        })
        .then(function () {
          // always executed
          commit("requested");
        });
    });
  },
  duplicate({ commit }, { idSubcase, name }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/subcases/${idSubcase}/duplicate`, { name })
        .then((response) => {
          commit("Cases/addSubcase", response.data, { root: true });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async GetTemplate({ commit }, payload) {
    let { api, idMasterForm } = payload;
    commit("request");
    await api
      .getMasterForm(idMasterForm)
      .then((response) => {
        // handle success
        let file = {
          general: JSON.parse(response.data.general),
          specific: JSON.parse(response.data.specific),
        };
        commit("settemplate", file);
        commit("setSummaryFile", JSON.parse(response.data.summaryFile));
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  PutSubcase({ commit }, payload) {
    let { idSubcase, file, name, summaryFile, patients } = payload;
    commit("request");

    return new Promise((resolve, reject) => {
      axios
        .put(`/subcases/${idSubcase}`, {
          File: file,
          Name: name,
          SummaryFile: summaryFile,
          Patients: patients,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              resolve(response);
            } else {
              reject(response.data.message);
            }
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  async PostSubcase({ commit }, payload) {
    let {
      api,
      idOrganizationalUnit,
      file,
      caseName,
      idCase,
      summary,
      patients,
      perPatient,
    } = payload;
    let userName = session.get("healex-userName");
    file.UserName = userName;

    commit("request");
    await api
      .PostSubcase(
        caseName,
        JSON.stringify(file),
        idOrganizationalUnit,
        idCase,
        JSON.stringify(summary),
        patients,
        perPatient,
      )
      .then((response) => {
        commit("setIdCase", response.data.idSubcase);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async GetSalaryTypes({ commit }, payload) {
    let { api } = payload;
    commit("request");
    await api
      .getSalaryTypes()
      .then((response) => {
        // handle success
        commit("setSalaryList", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async GetSalaryInfo({ commit }, payload) {
    let { api, idSalary, index } = payload;
    commit("request");
    await api
      .getSalaries(idSalary)
      .then((response) => {
        // handle success
        commit("setSalaryInfo", { data: response.data, index: index });
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async GetSubcaseState({ commit }, payload) {
    let { api, idSubcase } = payload;
    commit("request");
    await api
      .GetSubcaseState(idSubcase)
      .then((response) => {
        // handle success
        commit("setState", response.data.status);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  GetStudyTypes({ commit }, payload) {
    let { api, enabled } = payload;

    return api
      .GetStudyTypes(enabled)
      .then((response) => {
        // handle success
        commit("setStudyTypes", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  },
  async GetSurcharges({ commit }, payload) {
    let { api, idCondition } = payload;

    await api
      .GetSurcharges(idCondition)
      .then((response) => {
        // handle success
        commit("setSurcharges", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  },
  async GetSurchargeTypes({ commit }, payload) {
    let { api } = payload;

    await api
      .GetSurchargesTypes(true)
      .then((response) => {
        // handle success
        commit("setSurchargeTypes", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  },
  async DeleteSubcase({ commit }, payload) {
    let { idSubcase } = payload;
    commit("request");
    axios
      .delete(`/subcases/${idSubcase}`)
      .then(() => {
        commit("Cases/DeleteSubcase", idSubcase, { root: true });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit("requested");
      });
  },
  putParameters({ commit }, { idSubcase, parameters }) {
    commit("request");
    return new Promise((resolve, reject) => {
      axios
        .put(`/parameters/subcases?idSubcase=${idSubcase}`, parameters)
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            console.log("parameter update error: ", error);
            reject(error);
          },
        )
        .finally(() => {
          commit("requested");
        });
    });
  },
  putMoveSubcase(_, payload) {
    let { sourceIdSubcase, targetIdSubcase } = payload;
    return new Promise((resolve, reject) => {
      axios.post(`subcases/${sourceIdSubcase}/${targetIdSubcase}/swap`).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
  updateMaterialCostsList({ commit }, payload) {
    let { itemChanged } = payload;
    commit("updateMaterialCosts", itemChanged);
  },
  addMaterialCostItem({ commit }, payload) {
    let { itemAdded } = payload;
    commit("addMaterialCost", itemAdded);
  },
  deleteMaterialCostItem({ commit }, payload) {
    let { itemDeleted } = payload;
    commit("deleteMaterialCost", itemDeleted);
  },
};

const mutations = {
  setAllowPerPatients(state, allowPerPatients) {
    state.allowPerPatients = allowPerPatients;
  },
  setcase: (state, caseInfo) => (state.caseInfo = caseInfo),
  settemplate: (state, caseInfo) => (state.template = caseInfo),
  setState: (state, caseState) => (state.caseState = caseState),
  setEditor: (state, editor) => (state.currentEditor = editor),
  setIdCase: (state, caseId) => (state.caseId = caseId),
  setSalaryList: (state, list) => (state.salaryList = list),
  setSalaryInfo: (state, info) =>
    (state.salaryList[info.index].Salaries = info.data),
  updatecase: (state, caseFile) => (state.caseInfo = caseFile),
  request: (state) => {
    state.loading = true;
  },
  requested: (state) => {
    state.loading = false;
  },
  setStudyTypes: (state, data) => {
    state.studyTypes = data;
  },
  setSurcharges: (state, data) => {
    state.surcharges = data;
  },
  setSurchargeTypes: (state, data) => {
    state.surchargeTypes = data;
  },
  setSummaryFile: (state, data) => {
    state.summaryFile = data;
  },
  setMaterialCosts: (state, data) => {
    state.materialCosts = data;
  },
  updateMaterialCosts: (state, data) => {
    const index = state.materialCosts.findIndex((x) => x.idCostItem == data.Id);
    if (index !== -1) {
      state.materialCosts[index].costItemName = data.Name;
      state.materialCosts[index].costItemCost = data.Cost;
      state.materialCosts[index].costItemFinalCost = data.FinalCost;
    }
  },
  addMaterialCost: (state, data) => {
    let newItem = {};
    newItem.idCostCategory = data.costCategoryId;
    newItem.costCategoryName = data.costCategoryName;
    newItem.idCostItem = data.Id;
    newItem.identifierCostCategory = data.identifierCostCategory;
    newItem.costItemName = data.Name;
    newItem.costItemCost = data.Cost;
    newItem.costItemFinalCost = data.FinalCost;

    state.materialCosts.push(newItem);
  },
  deleteMaterialCost: (state, data) => {
    const index = state.materialCosts.findIndex((x) => x.idCostItem == data.Id);
    if (index !== -1) {
      state.materialCosts.splice(index, 1);
    }
  },
  setPatients: (state, patients) => (state.patients = patients),
  setPerPatient: (state, perPatient) => (state.perPatient = perPatient),
  setDuration: (state, duration) => (state.duration = duration),
  setName: (state, subcaseName) => (state.subcaseName = subcaseName),
  setCaseName: (state, caseName) => (state.caseName = caseName),
  setHasNoChanges: (state) => (state.hasChanges = false),
  setHasChanges: (state) => (state.hasChanges = true),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
