export default {
  lang: "de",
  app: {
    title: "Healex | Studget",
    description: "Studget Webanwendung",
  },
  login: {
    title: "Kalkulationswerkzeug für klinische Studien",
    login: "Login studget",
    "login-with": "Login mit Healex ClinicalSite",
    "register-with": "Register via Healex Self Service",
    recovery: "Passwort vergessen?",
    form: {
      username: "Benutzername",
      password: "Kennwort",
    },
    button: {
      register: "Registrieren",
      signin: "Anmelden",
    },
    anotherSessionOpened: {
      title: "Session-Warnung",
      description:
        "Diese Sitzung ist bereits durch einen andereren Benutzer geöffnet. Wollen sie diese schliessen und eine neue öffnen?",
    },
  },
  register: {
    form: {
      name: "Name",
      mail: "Email",
      comment: "",
    },
    button: {
      send: "Absenden",
      back: "Zurück",
    },
    title: "Registrierung",
  },
  passwordRecovery: {
    button: {
      recover: "Wiederherstellen",
      back: "Zurück",
    },
    title: "Passwort zurücksetzen",
    redirect: "You are being redirected to ClinicalSite reset password in: ",
    alias: "example{'@'}example.com",
    "invalid-email": "Please enter a valid email",
    field: "Email",
  },
  caselist: {
    dateformat: "DD.MM.YYYY HH:mm:ss",
  },
  views: {
    "master-form": {
      deafult: "Standard-Formularvorlage",
      title: "Formularvorlage",
      "title-list": "Formularvorlagen ({0})",
      name: "Name: ",
      nameForm: "Formularvorlage Name",
      status: "Status: ",
      "organizational-unit": "Organisationseinheit: ",
      "last-modified": "Zuletzt geändert: ",
      allowPerPatients: "Kalkulation pro Patient",
      none: "Keine",
      parent: "Übergeordnete Vorlage",
      OUInstructions: "Bitte wählen sie die Organisationseinheit aus",
      nameInstructions: "Bitte geben Sie einen Namen ein",
      organizationUnitRequired: "Organisationseinheit ist notwendig",
      nameRequired: "Name ist notwendig",
      parentRequired: "Vorläufer ist notwendig",
      selectSummations: "Summen auswählen",
      view: "Ansichtsmodus",
      addItem: "Element hinzufügen",
      addTask: "Aufgabe hinzufügen",
      addTaskCategory: "Aufgabenkategorie hinzufügen",
      addSummation: "Summen hinzufügen",
      deleteMainCategory: "Hauptkategorien löschen",
      addMainCategory: "Hauptkategorien hinzufügen",
      deleteCostCategory: "Kostenkategorie löschen",
      addCostCategory: "Kostenkategorie hinzufügen",
      buttons: {
        addMaterialCostItem: "Materialkosten hinzufügen",
      },
      modals: {
        costItemSelection: {
          globalSearchLabel: "Katalogübergreifende Suche",
          category: "Kategorie",
          unitName: "Eintrag",
          information: "Information",
        },
      },
    },
    pageNofound: {
      Title: "Hoppla! Seite nicht gefunden",
      goToHome: "Nach Startseite gehen",
      subtitle: "Die von Ihnen gesuchte Seite existiert leider nicht.",
    },
    confirmationEmail: {
      Title: "Ihre E-Mail wurde bestätigt",
      goToLogin: "Zur Anmeldung gehen",
    },
    customer: {
      deafult: "Standard-Kunden",
      title: "Kunden",
      ShowCustomersEnable: "akivierte Kunden anzeigen",
      AddCustomer: "Kunde hinzufügen",
      EditCustomer: "Kunde bearbeiten",
      RemoveCustomer: "Kunde löschen",
      RemoveCustomerPermanently: "Dauerhaft entfernen",
      ModalRemove: {
        title: "Sind Sie sicher, dass Sie löschen möchten?",
        subtitle: "Der Kunde wird endgültig aus der Datenbank entfernt",
      },
      ActivateCustomer: "Kunde aktivieren",
      GoToUser: "Benutzer",
      EnterCustomerName: "Kundenname eingeben",
      Cancel: "Abbrechen",
      fields: {
        id: "Id",
        name: "Name",
        enabled: "Aktiviert",
        unlimited: "Unlimited",
        maxUsers: "Max users",
        currentUser: "Current User",
      },
      CustomerUpdated: "Kunde aktualisiert",
      CustomerUpdatedSuccessfuly: "Der Kunde wurde erfolgreich aktualisiert",
      ErrorOnUpdate: "Es war ein Fehler bei der Aktualisierung",
      Error: "Fehler",
      CustomerActivated: "Kunde aktiviert",
      CustomerActivatedSuccessfuly: "Der Kunde wurde erfolgreich aktiviert",
      ErrorOnActivate: "Es war ein Fehler beim Aktivieren",
      CustomerRemoved: "Kunde entfernt",
      CustomerRemovedSuccessfuly: "Der Kunde wurde erfolgreich entfernt",
      ErrorOnRemove: "Es war ein Fehler beim Entfernen",
      features: "Features",
    },
    ou: {
      deafult: "Voreingestellt Organisationseinheit",
      ShowEnable: "aktivierte Organisationseinheiten anzeigen",
      Add: "Organisationseinheit hinzufügen",
      Edit: "Organisationseinheit bearbeiten",
      Remove: "Organisationseinheit löschen",
      Activate: "Organisationseinheit aktivieren",
      EnterName: "Organisationseinheit eingeben",
      OrganizationalUnitNameRequired: "Organisationseinheit name ist notwendig",
      modalOU: {
        nameLabel: "Name",
        nameInvalidFeedback: "Organisationseinheit name ist notwendig",
        namePlaceholder: "Organisationseinheit eingeben",
        emailLabel: "Reviewer Sammel-E-Mail-Adresse",
        emailInvalidFeedback: "A valid email is required",
        emailPlaceholder: "Enter default email for notifications",
      },
      Cancel: "Abbrechen",
      fields: {
        Id: "Id",
        Name: "Name",
        Enabled: "Aktiviert",
      },
      Updated: "Organisationseinheit aktualisiert",
      UpdatedSuccessfuly:
        "Die Organisationseinheit wurde erfolgreich aktualisiert",
      ErrorOnUpdate: "Es war ein Fehler bei der Aktualisierung",
      Error: "Fehler",
      Activated: "Aktivierte Organisationseinheit",
      ActivatedSuccessfuly:
        "Die Organisationseinheit wurde erfolgreich aktiviert",
      ErrorOnActivate: "Es war ein Fehler beim Aktivieren",
      Removed: "Organisationseinheit entfernt",
      RemovedSuccessfuly: "Die Organisationseinheit wurde erfolgreich entfernt",
      ErrorOnRemove: "Es war ein Fehler beim Entfernen",
      Parameters: "Parameter",
      title: "Organisationseinheiten",
      showEnabled: "Aktivierte Organisationseinheiten anzeigen",
      deactivateOU: "Organisationseinheit deaktivieren",
    },
    settings: {
      title: "Administrative Kosten",
      subtitle: "Vergütungen",
      Customers: "Kunden",
      OrganizationalUnit: "Organisationseinheiten",
      ChangeMasterForm: "Master-Formular ändern",
      SalaryType: "Vergütungsart: ",
      PleaseSelectAnOption: "Bitte wählen Sie eine Option",
      fields: {
        Id: "Id",
        CategoryName: "Vergütungsgruppe",
        1: "Vergütungsstufe 1",
        2: "Vergütungsstufe 2",
        3: "Vergütungsstufe 3",
        4: "Vergütungsstufe 4",
        5: "Vergütungsstufe 5",
        6: "Durchschnitt",
        Durchschnitt: "Durchschnitt",
        Average: "Durchschnitt",
      },
      administrativeCostCategories: "Kategorie",
      selectSalaryType: "Bitte wählen Sie eine Vergütungsart aus.",
      newSalaryType: "New Salary Type",
      updateSalaryType: "Update Salary Type",
      salaryTypeName: "Vergütungsartname",
      workingHours: "Jahresarbeitsstunden",
      customer: {
        title: "Kundenspezifische Einstellungen",
        months: "Dauer (Monate) Wechsel für Durchschnittswerte",
        surcharge: "Aufschlag (Prozent) für externe Personalkosten",
        cancel: "Abbrechen​",
      },
      context: {
        addSeniority: "Vergütungsart hinzufügen",
        deleteSeniority: "Vergütungsart löschen",
        addCategory: "Kategorie hinzufügen",
        deleteCategory: "Kategorie löschen",
      },
    },
    home: {
      title: "Startseite",
      MasterForm: "Formularvorlagen",
      Settings: "Einstellungen",
      AdministrativeCosts: "Verwaltungskosten",
      OrganizationalUnits: "Organisationseinheiten",
      Customers: "Kunden",
      Users: "Benutzer",
      Profile: "Profil",
      SignOut: "Abmeldung",
      Surcharges: "Auf/Abschläge",
      Conditions: "Bedingungen",
      CaseTemplateList: "Kostenkalkulationsvorlagen",
      status: "Status",
      duplicateSubcase: "Teilkalkulation duplizieren",
      about: "Über diese Version",
      generalSummations: "Summen",
      help: "Hilfe",
      duplicateCase: "Kostenkalkulation duplizieren",
      caseName: "Fall-Name:",
      caseNameRequired: "Fallname ist erforderlich",
    },
    users: {
      title: "Benutzer",
      ShowUsersEnable: "Aktivierte Benutzer anzeigen",
      AddUser: "Benutzer hinzufügen",
      EditUser: "Benutzer bearbeiten",
      RemoveUser: "Benutzer deaktivieren",
      RemovePermanentlyUser: "Dauerhaft entfernen",
      ActivateUser: "Benutzer aktivieren",
      ManageRoles: "Rollen verwalten",
      ChangePassword: "Passwort zurücksetzen",
      Message: "Sind Sie sicher, dass Sie Ihr Passwort zurücksetzen möchten?",
      fields: {
        UserName: "Benutzername",
        Name: "Name",
        Mail: "Email",
        role: "Role",
      },
      UserDisabledSuccessfully: "Benutzer erfolgreich deaktiviert",
      DeactivateUser: "Benutzer deaktivieren",
      UserActivatedSuccessfully: "Benutzer erfolgreich aktiviert",
      ResetingPasswordSuccessfully: "Passwort erfolgreich zurücksetzen",
      ResetingPassword: "Passwort zurücksetzen",
    },
    user: {
      title: "Benutzer",
      NewUser: "Neuer Benutzer",
      SaveUser: "Benutzer speichern",
      GeneralData: "Allgemeine Daten",
      User: "Benutzer:",
      EnterUser: "Benutzer eingeben",
      Name: "Name:",
      EnterName: "Name eingeben",
      Email: "Email",
      EnterEmail: "Email eingeben",
      Customer: "Kunde",
      EnterCustomer: "Kunde eingeben",
      UserDataLoaded: "Benutzerdaten geladen",
      UserLoading: "Benutzer laden",
      Required: "notwendig",
      RequiredEmail: "Erforderliche gültige E-Mail.",
    },
    roles: {
      UserDataLoaded: "Benutzerdaten geladen",
      UserLoading: "Benutzer laden",
      AddRole: "Rolle hinzufügen",
      RemoveRole: "Rolle löschen",
      OU: "Organisationseinheit:",
      Roles: "Rollen:",
      fields: {
        IdOrganizationalunit: "Organisationseinheit-ID",
        OrganizationalUnitName: "Organisationseinheit",
        RoleName: "Rollen",
      },
      LoadingRoles: "Laden von Rollen",
      LoadingOrganizationalUnits: "Laden von Organisationseinheit",
      Warning: "Warnung !",
      atLeastOneRole: "Bitte mindestens eine Rolle wählen vor dem Verlassen",
    },
    surcharges: {
      title: "Auf/Abschläge",
      ShowSurchargeEnable: "Aktivierte Auf/Abschläge anzeigen",
      AddSurcharge: "Auf/Abschlag hinzufügen",
      EditSurcharge: "Auf/Abschlag bearbeiten",
      RemoveSurcharge: "Auf/Abschlag deaktivieren",
      ActivateSurcharge: "Auf/Abschlag aktivieren",
      EnterSurchargeName: "Auf/Abschläge Name",
      Cancel: "Abbrechen",
      fields: {
        Id: "Id",
        Name: "Name",
        Enabled: "Aktiviert",
      },
      SurchargeUpdated: "Der Auf/Abschlag wurde aktualisiert",
      SurchargeUpdatedSuccessfuly:
        "Der Auf/Abschlag wurde erfolgreich aktualisiert",
      ErrorOnUpdate: "Es war ein Fehler bei der Aktualisierung",
      Error: "Fehler",
      SurchargeAdded: "Der Auf/Abschlag wurde hinzugefügt",
      SurchargeAddedSuccessfuly:
        "Der Auf/Abschlag wurde erfolgreich hinzugefügt",
      ErrorOnAdd: "Es war ein Fehler bei der Aktualisierung",
      SurchargeActivated: "Der Auf/Abschlag wurde aktiviert",
      SurchargeActivatedSuccessfuly:
        "Der Auf/Abschlag wurde erfolgreich aktiviert",
      ErrorOnActivate: "Es war ein Fehler beim Aktivieren",
      SurchargeRemoved: "Der Auf/Abschlag wurde gelöscht",
      SurchargeRemovedSuccessfuly:
        "Der Auf/Abschlag wurde erfolgreich gelöscht",
      ErrorOnRemove: "Es war ein Fehler beim Entfernen",
      EditNameSurcharge: " bearbeiten",
    },
    conditions: {
      ShowConditionEnable: "aktivierte Bedingungen anzeigen",
      AddCondition: "Bedingung hinzufügen",
      EditCondition: "Bedingung bearbeiten",
      RemoveCondition: "Bedingung löschen",
      ActivateCondition: "Bedingung aktivieren",
      EnterConditionName: "Bedingungen Name",
      ConditionNameRequired: "Bedingung name ist notwendig",
      Cancel: "Abbrechen",
      fields: {
        Id: "Id",
        Name: "Name",
        Enabled: "Aktiviert",
      },
      ConditionUpdated: "Die Bedingung wurde aktualisiert",
      ConditionUpdatedSuccessfuly:
        "Die Bedingung wurde erfolgreich aktualisiert",
      ErrorOnUpdate: "Es war ein Fehler bei der Aktualisierung",
      Error: "Fehler",
      ConditionAdded: "Die Bedingung wurde hinzugefügt",
      ConditionAddedSuccessfuly: "Die Bedingung wurde erfolgreich hinzugefügt",
      ErrorOnAdd: "Es war ein Fehler bei der Aktualisierung",
      ConditionActivated: "Die Bedingung wurde aktiviert",
      ConditionActivatedSuccessfuly:
        "Die Bedingung wurde erfolgreich aktiviert",
      ErrorOnActivate: "Es war ein Fehler beim Aktivieren",
      ConditionRemoved: "Die Bedingung wurde gelöscht",
      ConditionRemovedSuccessfuly: "Die Bedingung wurde erfolgreich gelöscht",
      ErrorOnRemove: "Es war ein Fehler beim Entfernen",
      EditNameCondition: " bearbeiten",
      title: "Bedingungen",
      showEnabled: "Aktivierte Bedingungen anzeigen",
      deactivateCondition: "Bedingung deaktivieren",
    },
    subCases: {
      NameCase: "Kostenkalkulation",
      nameSubcase: "Subcase Name",
      case: "Kostenkalkulation",
      buttons: {
        view: "Ansichtsmodus",
        edit: "Bearbeiten",
        closeCase: "Schließen",
        export: "Exportieren​",
        print: "Drucken",
        comment: "Kommentar",
      },
      sections: {
        StudyTypeSelection: {
          title: "Studientyp:",
          numPatients: "Anzahl der Patienten",
          perPatients: "Per patient",
          selectionOptionTrue:
            "Kalkulation auf einen eingeschlossenen Patienten",
          selectionOptionFalse:
            "Kalkulation auf die Maximalzahl der einzuschließenden Patienten",
          selectOneItem: "Bitte wählen Sie einen Artikel aus",
        },
      },
      newSubcase: "Neue Subcase",
      nameInstructions: "Bitte geben Sie einen Subcasenamen ein",
      missingName: "Missing name",
      numPatientsInvalid: "Bitte überprüfen Sie die Patientenanzahl",
    },
    cases: {
      newCase: "Neuer Fall",
      organizationUnit: "Organisationseinheit",
      organizationUnitRequired: "Organisationseinheit ist notwendig",
      studyTypeRequired: "Studientyp ist notwendig",
      organizationUnitInstructions:
        "Bitte wählen Sie eine Organisationseinheit",
      name: "Name",
      nameRequired: "Fallname ist notwendig",
      notAllowedSymbols:
        "The case name cannot contain any of the following symbols: / * ? [ ] : |",
      nameInstructions: "Bitte geben Sie einen Fallnamen ein",
      duration: "Dauer",
      durationRequired: "Dauer ist notwendig",
      durationInMonths: "Bitte geben Sie die Falldauer ein (Monate)",
      masterForm: "Formularvorlage",
      masterFormRequired: "Formularvorlage ist notwendig",
      masterFormInstructions: "Bitte wählen Sie ein Formularvorlage",
      studyTypeInstructions: "Bitte wählen Sie einen Studientyp",
      creatingCase: "Fall schaffen...",
      lastModified: "Zuletzt geändert",
      ascending: "Von A bis Z sortieren",
      descending: "Von Z bis A sortieren",
      SortBy: "Sortieren nach",
      FilterBy: "Filtern nach",
      caseTemplate: "Fallvorlage",
      caseTemplateInstructions: "Bitte wählen Sie eine Fallvorlage",
      caseTemplateRequired: "Fallvorlage ist notwendig",
      title: "Kostenkalkulation",
      selectCase: "Wählen Sie eine Kostenkalkulation",
      default: "Default",
    },
    userProfile: {
      userProfile: "Benutzerprofil",
      userEditOptions: {
        editProfile: "Profil bearbeiten",
        changePassword: "Passwort zurücksetzen",
      },
      userAttributes: {
        name: "Name",
        nameInstructions: "Name eingeben",
        email: "E-Mail",
        emailInstructions: "E-Mail eingeben",
        SMSAccount: "ClinicalSite-Account",
        customer: "Kunde",
        link: "Link account",
        linkSuccess: "ClinicalSite-Account linked",
        success: "Success",
      },
      cancel: "Abbrechen",
    },
    caseTemplateEdition: {
      addNewGeneralSummation: "Summe hinzufügen",
      deleteGS: "Allgemeine Summierung löschen",
      deleteConfirmGS:
        "Sind Sie sicher, dass Sie die allgemeine Summierung löschen wollen?",
      basisInformation: "Basis Informationen",
      structure: "Struktur",
      parameters: "Parameter",
      materialCosts: "Materialkosten",
      summations: "Summen",
      subcaseName: "Teilkalkulation",
      masterForm: "Teilkalkulationsvorlage",
      blocked: "gesperrt",
      blockedStatus: "Gesperrt (Status: gesperrt / entsperrt)",
      addRow: "Zeile hinzufügen",
      editRow: "Zeile bearbeiten",
      deleteRow: "Zeile löschen",
      "delete-template": "Feld löschen",
      unblocked: "entsperrt",
      subcaseNameDescription: "Bitte geben Sie einen Subcasenamen ein",
      newRow: "Neue Zeile",
      saveRow: "Zeile speichern",
      updateRow: "Zeile aktualisieren",
      subcaseNameRequired: "Subcase name ist notwendig",
      deleteConfirm: "Möchten Sie diese Zeile wirklich löschen?",
      "delete-case-template": "Möchten Sie dieses Feld wirklich löschen?",
      moveUp: "Aufsteigen",
      moveDown: "Absteigen",
      editParameter: "Parameter bearbeiten",
      defaultValueRequired: "Standardwert für Parameter ist notwendig",
      notAllowedValue: "Bitte nur positive Werte eintragen",
      defaultValueDescription: "Standardwert für Parameter",
      defaultValue: "Standardwert",
      parameter: "Parameter",
      parameterInstructions: "Bitte geben Sie einen Parameter ein",
      parameterRequired: "Parameter ist notwendig",
      addParameter: "Parameter hinzufügen",
      newParameter: "Neue Parameter",
      idParameter: "Id",
      parameterName: "Parameter Name",
      deleteParameter: "Parameter löschen",
      showSubcases: "Kostenkalkulation anzeigen",
      showBudget: "Budget anzeigen",
      generalSummation: "Summen",
      Name: "Name",
      EditName: "Name bearbeiten",
      NameIsRequired: "Name ist erforderlich",
      fields: {
        title: "Zusatzfelder",
        "add-field": "Feld hinzufügen",
        "new-field": "Zusatzfeld",
        "edit-field": "Feld bearbeiten",
        "update-field": "Feld bearbeiten",
        "delete-field": "Feld löschen",
        "update-name": "Update name",
        "update-study-type": "Update study type",
        "update-duration": "Update duration",
        "move-up": "Move field up",
        "move-down": "Move field down",
        "ok-new": "Hinzufügen",
        name: "Name",
        "name-invalid": "Name is required",
        "name-placeholder": "Bitte geben Sie den Namen ein",
        value: "Wert",
        "value-invalid": "Value is required",
        "value-placeholder": "Beispiel Angabe",
        "study-type": "Study type",
        "select-study": "Please select a study type",
        onlyNumbers: "Beschränken auf",
        onlyNumbersTrue: "0-9",
        onlyNumbersFalse: "Keine",
        mandatory: "Pflichtangabe",
        "mandatory-true": "Ja",
        "mandatory-false": "Nein",
        reviewer: "Nur für Reviewer:",
        "field-title-name": "Name",
        "field-title-value": "Beispiel",
        "field-title-mandatory": "Pflichteingabe",
        "field-title-reviewer": "Nur für Reviewer",
        "generic-forbidden":
          "Der Benutzer kann diese Aktion nicht durchführen.",
        idSubcase: "Teilkalkulation ID",
        subcaseName: "Teilkalkulation",
        costCategoryName: "Kategorie",
        costItemName: "Eintrag",
        costItemCost: "Einzelpreis",
        costItemFinalCost: "Gesamtkosten",
      },
    },
    caseTemplateList: {
      screeTitle: "Liste der Kostenkalkulationsvorlagen",
      ShowEnableCaseTemplates: "Aktivierte Kostenkalkulationsvorlagen anzeigen",
      AddCaseTemplate: "Kostenkalkulationsvorlage hinzufügen",
      EditCaseTemplate: "Kostenkalkulationsvorlage bearbeiten",
      createANewCaseTemplate: "Neue Kostenkalkulationsvorlage hinzufügen",
      name: "Name",
      id: "Id",
      newTemplateName: "Name der Kostenkalkulationsvorlage",
      nameRequired: "Name der Fallvorlage notwendig",
      OU: "Organisationseinheit",
      OURequired: "Organisationseinheit notwendig",
      OUInstructions: "Bitte wählen sie die Organisationseinheit aus",
      removeCaseTemplate: "Kostenkalkulationsvorlage deaktivieren",
      activate: "Kostenkalkulationsvorlage aktivieren",
    },
    parameters: {
      title: "Parameter",
      addParameter: "Parameter hinzufügen",
      editParameter: "Parameter bearbeiten",
      deleteParameter: "Parameter löschen",
      showEnabledParameters: "Aktivierte Parameter anzeigen",
      enterParameterValue: "Wert eingeben",
    },
    generalSummations: {
      title: "Summen",
      enterSummationName: "Summenbezeichnung eingeben",
      summationAddedSuccessfuly: "Die Summierung wurde erfolgreich hinzugefügt",
      errorOnAdd: "Es gab einen Fehler beim Hinzufügen von",
      generalSummationAdded: "Summierung wurde hinzugefügt",
      editSummationName: " Summierung bearbeiten",
      deafult: "Allgemeine Standard-Summierung",
      updated: "Allgemeine Zusammenfassungen aktualisiert",
      updatedSuccessfuly:
        "Die allgemeinen Zusammenfassungen wurden erfolgreich aktualisiert",
      removed: "Allgemeines Resümee entfernt",
      removedSuccessfuly:
        "Die allgemeine Summierung wurde erfolgreich entfernt",
      activated: "Allgemeine Summierung Aktiviert",
      activatedSuccessfuly:
        "Die Allgemeine Summierung wurde erfolgreich aktiviert",
      addGeneralSummation: "Summe hinzufügen",
      editGeneralSummation: "Summe bearbeiten",
      removeGeneralSummation: "Summe deaktivieren",
      activateGeneralSummation: "Summe aktivieren",
      cancel: "Abbrechen",
      showSummationEnable: "Aktivierte Summen anzeigen",
      fields: {
        id: "ID",
        name: "Name",
        enabled: "aktiviert",
      },
    },
    help: {
      dateformat: "DD.MM.YYYY",
      "file-name-title": "Datei-Name",
      modal: {
        "create-article": "Neuer Artikel",
        "update-article": "Artikel bearbeiten",
        "delete-article": "Artikel löschen",
        "delete-article-message":
          "Sind Sie sicher, dass Sie den Artikel löschen wollen?",
        "download-files": "Datei herunterladen",
        "upload-files": "Dateien",
        "new-file": "Neue Datei",
        "edit-file": "Datei bearbeiten",
        "delete-file": "Datei löschen",
        "create-article-label-name": "Name:",
        "invalid-feedback-name": "Bitte geben Sie einen Namen ein",
        "create-article-placeholder-name": "Please enter a name",
        "create-article-label-description": "Beschreibung:",
        "invalid-feedback-description": "Please enter a valid description",
        "create-article-placeholder-description":
          "Bitte geben Sie eine Beschreibung ein",
        "new-file-label": "Datei:",
        "invalid-feedback-file": "Please select a file",
        "new-file-placeholder": "Keine Datei ausgewählt",
        "browse-file-placeholder": "Durchsuchen",
        "edit-file-label": "Datei:",
        "edit-file-placeholder": "Keine Datei ausgewählt",
        "delete-file-message": "Are you sure you want to delete the file?",
        "file-size": "Select a file size less than 5mb",
        "file-no-pdf": "Please select a PDF",
        "temporal-errorMessage": "Something went wrong",
      },
      buttons: {
        "create-article": "Neuer Artikel",
        "update-article": "Artikel bearbeiten",
        "delete-article": "Artikel löschen",
        "download-files": "Download article files",
        "new-file": "Neue Datei",
        "edit-file": "Datei bearbeiten",
        "delete-file": "Datei löschen",
        close: "Schließen",
        next: "Next",
      },
    },
    reports: {
      title: "Berichte",
      dateFormat: "DD.MM.YYYY HH:mm",
      materialReportTitle: "Sachkostenbericht",
      personalReportTitle: "Personalkostenbericht",
      buttons: {
        startDateLabel: "Start-Datum",
        endDateLabel: "End-Datum",
        statusLabel: "Status",
        selectStatus: "Wählen Sie einen Status:",
        searchPersonalReport: "Bericht erstellen",
        exportTooltip: "Bericht exportieren",
        datePickerReset: "Zurücksetzen",
        datePickerLabel:
          "Sie können mit den Pfeiltasten durch den Kalender navigieren",
        noTagsAvailable: "Keine weitere Status verfügbar",
      },
      fields: {
        idCase: "ID der Kostenkalkulation",
        cuNummer: "CU Nummer",
        caseName: "Kostenkalkulation",
        idOrganizationUnit: "ID der Organisationseinheit",
        organizationUnitName: "Organisationseinheit Name",
        idStatus: "Status",
        statusChangeDate: "Datum Statusänderung",
        resourceName: "Ressource",
        salaryType: "Vergütungsart",
        category: "Kategorie",
        years: "Dauer",
        annualCost: "Jährliche Kosten",
        hourlyRate: "Stundensatz",
        externalRate: "Stundensatz (ext.)",
        totalResourceCost: "Gesamtkosten der Ressource",
        totalResourceExternal: "Gesamte externe Kosten der Ressource",
        totalResourceHours: "Gesamte Ressourcenstunden",
      },
      materialCostFields: {
        subcaseName: "Teilkalkulation",
        itemName: "Eintrag",
        individualCost: "Einzelpreis",
        totalCost: "Gesamtkosten",
        information: "Information",
        count: "Anzahl",
        costCategory: "Katalog",
        catalogCategory: "Kategorie",
      },
    },
  },
  HeaderCase: {
    ChangeName: {
      title: "Fallname ändern",
      form: {
        Name: "Name",
        "Name-invalid-feedback": "Name ist notwendig",
      },
    },
    ChangeOwner: {
      title: "Eigentümer ändern",
      newOwner: "Neuer Besitzer",
      NameRequired: "Name ist notwendig",
      PleaseSelectOption: "Bitte wählen Sie eine Option",
    },
    ClosingCase: {
      title: "Gehäuse schließen",
      confirmation: "Sind Sie sicher, dass Sie den Fall schließen wollen?",
      closed: "Geschlossen",
    },
    Display: {
      Internal: "Intern",
      External: "Extern",
      Totals: "Summen",
    },
    Edit: {
      Internal: "Intern",
      External: "Extern",
      Totals: "Summen",
      Open: "Öffnen",
    },
    Fields: {
      Subcase: "Subcase",
      Summation: "Kostenart",
      Cost: "Kosten",
      Budget: "Budget",
      Difference: "Differenz",
    },
    ReviewersDistributionList: {
      tabTitle: "Reviewer",
      title: "Liste der Reviewer",
      addReviewer: "Reviewer hinzufügen",
      reviewerLabel: "Reviewer:",
      reviewerInvalidFeedback: "Bitte wählen Sie eine Person",
      reviewerPlaceholder: "Bitte einen Reviewer auswählen",
      deleteReviewer: "Reviewer löschen",
      reviewerConfirmation: "Are you sure you want to delete the reviewer?",
    },
    HeaderToolbar: "Kopfzeilen-Symbolleiste",
    DisplayText: "Anzeige",
    EditText: "Bearbeiten",
    VisualizationModeTitle: "Enter visualization mode",
    VisualizationMode:
      "Shortly you will be able to see the header information in visualization mode",
    EditModeTitle: "Enter edit mode",
    EditMode:
      "Shortly you will be able to see the header information in edit mode",
    OrganizationalUnit: "Organisationseinheit: ",
    Owner: "Besitzer: ",
    MothsDuration: "Dauer (in Monaten):",
    CreatedOn: "Erstellt am: ",
    CreatedBy: "Erstellt von: ",
    LastModified: "Zuletzt geändert: ",
    ClosedOn: "Geschlossen am: ",
    Status: "Status: ",
    UnsavedChangesTitle: "Nicht gespeicherte Änderungen",
    UnsavedChangesMessages:
      "Wollen Sie wirklich gehen? Sie haben nicht gespeicherte Änderungen!",
    Open: "öffnen",
    StudyType: "Studientyp:",
    None: "Keine",
    UserCollaboration: "Benutzerzusammenarbeit",
    RemoveUser: "Benutzer entfernen",
    AddUser: "Benutzer hinzufügen",
    name: "Name",
    mail: "Email",
    actions: "Aktionen",
    caseBeingEdited:
      "Die Kostenkalkulation wird bereits von einem anderen Benutzer bearbeit. Wollen sie fortfahren?",
    reopenCase: "Möchten Sie den Case wirklich zum Bearbeiten wieder öffnen?",
    AcceptCase: {
      title: "Kostenkalkulation akzeptieren",
      acceptingCaseError: "ein Fehler ist aufgetreten",
      acceptingCaseSuccessful: "Kostenkalkulation akzeptiert",
      confirmation: "Möchten Sie den Case akzeptieren?",
      missingFields:
        "Die Pflichtfelder sind nicht ausgefüllt. Die Kostenkalkulation kann erst akzeptiert werden, wenn alle Pflichtfelder ausgefüllt wurden.",
    },
    SubmittedCase: {
      title: "Case einreichen",
      submittedCaseSuccessful: "Kostenkalkulation eingereicht",
      confirmation: "Möchten Sie den Case einreichen?",
    },
    ReturnCase: {
      title: "Kostenkalkulation ablehnen",
      returnCaseSuccessful: "Kostenkalkulation abgelehnt",
      confirmation: "Möchten Sie den Case ablehnen?",
    },
  },
  messages: {
    titles: {
      warning: "Achtung",
      DeleteCase: "Kostenkalkulation löschen",
      CloaseCase: "Kostenkalkulation wird geschlossen",
      ExitNotSaved: "Ungespeicherte Änderungen!",
      Success: "Erfolg",
      Error: "Fehler",
    },
    prompts: {
      DeleteCase: "Wollen Sie diesen Case wirklich löschen?",
      CloseCase: "Wollen Sie diesen Case wirklich schließen?",
      ExitNotSaved:
        "Wollen Sie diese Seite verlassen? Ungespeicherte Änderungen gehen dann verloren",
    },
    MaxTaskResources:
      "Dieser Aufgabe wurden bereits alle verfügbaren Ressourcen zugeordnet.",
    MasterFormUpdated: "Die Formularvorlage wurde erfolgreich aktualisiert",
    MasterFormNotUpdated:
      "Beim Speichern des Formularvorlage ist ein Fehler aufgetreten.",
    OpenCaseError: "Konnte keine neue Case öffnen",
    ErrorSaving: "Es war ein Fehler bei der Aktualisierung",
    masterFormViewMode:
      "In Kürze erscheint die Formularvorlage im Ansichtsmodus",
    subcaseViewMode: "In Kürze erscheint der Subcase im Ansichtsmodus",
    externalLowerCost:
      "Die externen Kosten sind geringer als die internen Kosten",
    CaseSubmittedCanNotEdited:
      "Fälle mit dem Status 'eingereicht' können nicht bearbeitet werden.",
    212: "Die Organisationseinheit kann nicht deaktiviert werden, da es noch mindestens einen Benutzer gibt, der dieser Organisationseinheit zugewiesen ist.",
    213: 'Die Organisationseinheit kann nicht deaktiviert werden, da noch mindestens ein Fall vorhanden ist, der sich nicht im Status "geschlossen" befindet.',
  },
  placeholders: {
    TaskDescription: "Aufgabenbeschreibung",
    Comment: "Kommentar",
    NoInformationAdded: "keine Information hinzugefügt",
  },
  fields: {
    TimeCategories: {
      Description: "Beschreibung",
      Name: "Name",
    },
    CostCategories: {
      Item: "Element",
      identifier: "Identifier",
      limitedCatalogs: "Limited Catalogs",
    },
    MainCategories: {
      Category: "Kategorie",
    },
    TaskCategories: {
      Resource: "Ressource",
      Task: "Aufgabe",
      Hours: "Stunden",
      Parameters: "Parameter Wert",
      PerPatient: "€ pro Patient",
    },
    ResourcePool: {
      Resource: "Ressource",
      ResourcePool: "Ressourcenpool",
      SalaryType: "Vergütungsart",
      Category: "Kategorie",
      Years: "Jahre",
      AnnualCost: "jährliche Kosten",
      HourlyRate: "Stundensatz",
      ExternalRate: "Stundensatz (ext)",
      TotalResourceCost: "Gesamtkosten der Ressource",
      TotalResourceExternalCost: "Gesamte externe Kosten der Ressource",
      TotalResourceHours: "Gesamte Ressourcenstunden",
    },
    Resources: "Ressourcen",
    Summations: {
      Summations: "Summen",
      Name: "Name",
      Final: "Total €",
      Value: "Aufschlag/Abschlag",
      SelectFields: "Felder auswählen",
      CostCategories: "Kostenkategorien",
      CostItems: "Kostenelement",
      MainCategories: "Hauptkategorien",
      TaskCategories: "Aufgabenkategorien",
      Tasks: "Aufgaben",
      SummationsHeader: "Summen",
      OnlyPercentage: "Nur Prozentsatz",
      PerPatient: "Pro Patient",
      OnlyInternal: "Nur Intern",
    },
    Home: {
      Status: "Status",
      Cases: "Fälle",
      CaseName: "Fall-Name",
      OrganizationalUnit: "Organisationseinheit",
      Owner: "Besitzer",
      CreatedBy: "Erstellt von",
      CreatedOn: "Erstellt am",
      LastModified: "Zuletzt geändert",
      ClosedOn: "Geschlossen am",
      IdCase: "Case ID",
    },
    ParameterPanel: {
      Id: "Id",
      Name: "Name",
      Enabled: "Aktiviert",
      Value: "Wert",
    },
  },
  CostCategories: {
    Item: "Item",
  },
  MainCategories: {
    Category: "Category",
  },
  TaskCategories: {
    Resource: "Resource",
    Task: "Task",
    Hours: "Hours",
  },
  ResourcePool: {
    Resource: "Resource",
    ResourcePool: "Resource Pool",
    SalaryType: "Salary Type",
    Category: "Category",
    Years: "Years",
    AnnualCost: "Annual Cost",
    HourlyRate: "Hourly Rate",
    ExternalRate: "External Rate",
  },
  Summations: {
    Summations: "Summen",
    Name: "Name",
    Final: "Gesamt €",
    Value: "Surcharge Value",
    SelectFields: "Select Fields",
    CostCategories: "Cost Categories",
    CostItems: "Cost Items",
    MainCategories: "Main Categories",
    TaskCategories: "Task Categories",
    Tasks: "Aufgaben",
  },
  Home: {
    Status: "Status",
    Cases: "Fälle",
    CaseName: "Fall-Name",
    OrganizationalUnit: "Organisationseinheit",
    Owner: "Besitzer",
    CreatedBy: "Erstellt von",
    CreatedOn: "Erstellt am",
    LastModified: "Zuletzt geändert",
    ClosedOn: "Geschlossen am",
    AddCase: "Kostenkalkulation hinzufügen",
    SelectHeaderCase: "Wählen Sie ein einen Fall",
    displayHeaderCase: "Kostenkalkulationsdetails anzeigen",
    RemoveCase: "Kostenkalkulation löschen",
    PrintCase: "Kostenkalkulation drucken",
    AddSubcase: "Teilkalkulation hinzufügen",
    DisplaySubcase: "Teilkalkulation anzeigen",
    RemoveSubcase: "Teilkalkulation löschen",
    SubcasesNotFound: "Teilkalkulation nicht gefunden",
  },
  buttons: {
    AddItem: "Element hinzufügen",
    RemoveItem: "Element löschen",
    AddTaskCategory: "Aufgabenkategorie hinzufügen",
    AddTimeCategory: "Informationskategorie hinzufügen",
    DeleteTimeCategory: "Informationskategorie löschen",
    AddCostCategory: "Kostenkategorie hinzufügen",
    DeleteCostCategory: "Kostenkategorie löschen",
    AddMainCategory: "Hauptkategorie hinzufügen",
    DeleteMainCategory: "Hauptkategorie löschen",
    AddResource: "Ressource hinzufügen",
    AddTask: "Aufgabe hinzufügen",
    AddCategory: "Kategorie hinzufügen",
    AddCase: "Case hinzufügen",
    ChangeOwner: "Besitzer wechseln",
    OK: "OK",
    Cancel: "Abbrechen",
    SaveAndExist: "Speichern und beenden",
    discard: "Ja, wegwerfen",
    addUser: "Benutzer hinzufügen",
    insertTask: "Aufgabe einfügen",
  },
  general: {
    internal: "Intern",
    external: "Extern",
    save: "Speichern",
    cancel: "Abbrechen",
    warning: "Warning",
    name: "Name",
    search: "Suche",
    edit: "Bearbeiten",
    filter: "Filter",
    ok: "OK",
    status: "Status",
    viewMode: "Ansichtsmodus",
    loading: "Laden...",
    lastModified: "Zuletzt geändert",
    userName: "Benutzername",
    blockedInParent: "Gesperrt in übergeordneter Masterform",
    fromParent: "von übergeordneter Masterform",
    add: "Hinzufügen",
    view: "Anzeigen",
    delete: "Löschen",
    none: "Keine",
    customerSettings: "Einstellungen",
    download: "Herunterladen",
    downloading: "Herunterladen...",
    dataRequired: "Pflichtangabe",
    numberRequired: "Nur Zahlen erlaubt",
    configuration: "Konfiguration von ",
  },
  status: {
    1: "Offen",
    2: "Geschlossen",
    3: "In Bearbeitung​",
    4: "Eingereicht",
    5: "Akzeptiert",
    6: "Abgelehnt",
  },
  objects: {
    masterForm: "Formularvorlage",
    conditions: "Bedingungen",
    parameters: "Parameter",
    surcharges: "Auf/Abschläge",
    generalSummations: "Summen",
  },
  password: {
    title: "Kennwort ändern",
    text: "Kennwort-Richtlinienanforderungen:",
    lowercaseRequired: "Kleinbuchstaben sind erforderlich",
    uppercaseRequired: "Großbuchstaben sind erforderlich",
    numberRequired: "Zahlen sind erforderlich",
    symbolRequired: "Sonderzeichen sind erforderlich: ",
    charactersRequired: "Mindestens 8 Zeichen und maximal 12 Zeichen",
    newPassword: "Neues Kennwort",
    repeatPassword: "Neues Kennwort bestätigen",
    repeatPasswordRequired: "Bitte geben Sie das neue Kennwort ein.",
    lengthError: "Passwort muss 8 bis 12 Zeichen lang sein",
    patternError: "Passwort entspricht nicht dem erforderlichen Muster",
  },
  customer: {
    title: "Kunden",
    fields: {
      id: "Id",
      name: "Name",
      enabled: "Aktiviert",
      unlimited: "unbegrenzte Benutzer",
      maxUsers: "maximale Benutzer",
      currentUsers: "derzeitige Benutzer",
    },
    messages: {
      currentUsersMaxUsers:
        "Current users are more than the max allowed users.",
      confirmationDelete: "Sind Sie sicher, dass Sie löschen möchten?",
      customerDisabled: "Der Kunde wird deaktiviert",
    },
    buttons: {
      showEnabledCustomers: "akivierte Kunden anzeigen",
      addCustomer: "Kunde hinzufügen",
      editCustomer: "Kunde bearbeiten",
      removeCustomer: "Kunde löschen",
      removeCustomerPermanently: "Dauerhaft entfernen",
    },
    placeholders: {
      newCustomerName: "Kundenname eingeben",
      updateCustomerName: "Kundenname eingeben",
    },
    values: {
      limited: "begrenzt",
      unlimited: "unbegrenzt",
    },
  },
  "administrative-costs": {
    buttons: {
      uploadExcel: "Upload CSV",
      downloadExcel: "Download CSV template",
    },
    fields: {
      salaryFile: "CSV File",
    },
    feedback: {
      salaryFile: "CSV file is required",
    },
    "material-tab": "Sachkostenkataloge",
    "personal-tab": "Personalkostenkataloge",
    "material-cost-tab": {
      title: "Kostenkataloge:",
      "cost-option-default": "Bitte wählen Sie einen Kostenkatalog aus",
      subtitle: "Kosten",
      buttons: {
        "create-cost": "Neuen Kostenkatalog hinzufügen",
        "create-cost-title": "Neuer Kostenkatalog",
        "update-cost": "Kostenkatalog bearbeiten",
        "delete-cost": "Kostenkatalog löschen",
        "upload-csv": "Kostenkatalog importieren",
        "upload-csv-error-title": "Kostenkatalog importieren Fehler",
        "download-csv": "Kostenkatalogvorlage herunterladen",
        "create-category": "Neue Kostenkategorie hinzufügen",
        "update-category": "Update cost category",
        "delete-category": "Kostenkategorie löschen",
        "update-value": "Update cost value",
        noDateSelected: "Ohne Datum",
        datePickerHelp:
          "Sie können mit den Pfeiltasten durch den Kalender navigieren",
        "update-csv-title": "Kostenkatalog updaten",
        "update-csv-error-title": "Kostenkatalog updaten Fehler",
      },
      modals: {
        "create-cost-label": "Name des Kostenkatalogs:",
        "create-cost-invalid": "Name ist notwendig",
        "create-cost-placeholder": "Bitte geben Sie einen Namen ein",
        "update-cost-label": "Name des Kostenkatalogs:",
        "update-cost-invalid": "Name ist notwendig",
        "update-cost-placeholder": "Bitte geben Sie einen Namen ein",
        "delete-cost-message":
          "Sind Sie sicher, dass Sie die Kostenart löschen wollen?",
        "upload-csv-label": "CSV / Excel Datei",
        "upload-csv-invalid": "Keine Datei ausgewählt",
        "create-category-label": "Name der Kostenkategorie:",
        "create-category-invalid": "Name ist notwendig",
        "create-category-placeholder": "Bitte geben Sie einen Namen ein",
        "create-category-label-value": "Cost category value:",
        "create-category-invalid-value": "Name ist notwendig",
        "create-category-placeholder-value": "Bitte geben Sie einen Namen ein",
        "delete-category-message":
          "Sind Sie sicher, dass Sie die Kostenkategorie löschen wollen?",
      },
      warningMessages: {
        title: "Anleitung zum Update der Spalte “Ungültig”:",
        first:
          "Wenn das Feld in der Datei mit “J” oder “Y” gefüllt ist, wird die Checkbox aktiviert",
        second:
          "Wenn das Feld in der Datei mit “N” gefüllt ist, wird die Checkbox de-aktiviert",
        third: "Wenn das Feld leer ist, wird das Feld ignoriert",
        fourth:
          "Wenn das Feld einen anderen Inhalt hat, wird das Update abgebrochen",
      },
      fields: {
        unitId: "Unit ID",
        category: "Kategorie",
        unit: "Eintrag",
        information: "Information",
        unitCost: "Einzelpreis",
        validFrom: "Gültig ab (opt.)",
        validTo: "Gültig bis (opt.)",
        invalid: "Ungültig",
      },
    },
  },
  export: {
    title: "Exportieren",
    viewMode: {
      label: "Kostenansicht",
      options: {
        internal: "Intern",
        external: "Extern",
      },
    },
    format: {
      label: "Dateityp",
      options: {
        pdf: "PDF",
        excel: "Excel",
      },
    },
    options: {
      comments: "Kommentare",
      extended: "Erweitert",
      yes: "Ja",
      no: "Nein",
    },
    buttons: {
      partialCostCalculation: "Teilkalkulation exportieren",
      costCalculation: "Kostenkalkulation exportieren",
    },
  },
  partialCostCalculation: {
    perPatientOptions: [
      "Keine Patienten-Kalkulation",
      "Kalkulation auf einen eingeschlossenen Patienten",
      "Kalkulation auf die Maximalzahl der einzuschließenden Patienten",
    ],
  },
  comments: {
    button: "Kommentar",
    "no-comments": "keine Kommentare",
    title: "Kommentare Fall ",
    body: "Kommentare",
    reply: "Beantworten",
    resolve: "Lösen",
    "delete-warning":
      "Sie löschen diesen Eintrag und alle nachfolgenden Antworten.",
    "length-feedback":
      "Die Kommentarlänge darf zwischen 1 und 2000 Zeichen betragen.",
    "new-comment": "Neuer Kommentar",
    "reply-title": "Kommentar beantworten",
    "reply-to": "Als Antwort auf",
  },
  variableCosts: {
    materialButtonTitle: "Variable Kosten",
    hourlyRateButtonTitle: "Stundensatz",
    materialCommentText: "variabel",
    materialExplanatoryPlaceholder:
      "Beispiele: 120€/Std nach Aufwand // 3€ pro km Anfahrt",
  },
  dataPrivacy: {
    title: "Datenschutz",
    header: "Healex Studget Datenschutzerklärung",
    p1: "Datenschutz hat einen besonders hohen Stellenwert für die Geschäftsleitung und die Belegschaft der Healex GmbH. Innerhalb der Nutzungsdauer des Healex Studget Systems (https://Studget.org) der Healex GmbH werden zu keinem Zeitpunkt, Daten über Benutzer des Systems durch Tracking- und Profiling-Algorithmen oder anderen Algorithmen zur Erhebung Internetmarketing-technischen KPIs erhoben.",
    p2: "Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, E-Mail-Adresse, Benutzername oder Benutzerkennung der betroffenen Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und in Übereinstimmung mit den für die Healex GmbH geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchten wir die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren. Ferner werden betroffene Personen mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.",
    p3: "Das Healex Studget System und damit die Healex GmbH hat, als für die Verarbeitung Verantwortlicher, zahlreiche technische und organisatorische Maßnahmen umgesetzt, um den größtmöglichen Schutz der über unser Produkt (Studget) verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können Internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann.",
    section1: "1. Begriffsbestimmungen",
    s1p1: "Die Datenschutzerklärung des Healex Studget beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.",
    s1p2: "Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:",
    s1subtitle1: "Personenbezogene Daten",
    s1st1p1:
      "Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person”) beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.",
    s1subtitle2: "Betroffene Person",
    s1st2p1:
      "Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.",
    s1subtitle3: "Verarbeitung",
    s1st3p1:
      "Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.",
    s1subtitle4: "Einschränkung der Verarbeitung",
    s1st4p1:
      "Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.",
    s1subtitle5: "Profiling",
    s1st5p1:
      "Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.",
    s1subtitle6: "Pseudonymisierung",
    s1st6p1:
      "Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.",
    s1subtitle7: "Verantwortlicher oder für die Verarbeitung Verantwortlicher",
    s1st7p1:
      "Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.",
    s1subtitle8: "Auftragsverarbeiter",
    s1st8p1:
      "Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.",
    s1subtitle9: "Empfänger",
    s1st9p1:
      "Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.",
    s1subtitle10: "Dritter",
    s1st10p1:
      "Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.",
    s1subtitle11: "Einwilligung",
    s1st11p1:
      "Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.",
    section2: "2. Name und Anschrift des für die Verarbeitung Verantwortlichen",
    s2p1: "Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:",
    direction1: "Healex GmbH",
    direction2: "Sophienstraße 3",
    direction3: "51149 Köln",
    phone: "Tel.: +49 2203 5026368",
    email: "E-mail: info{'@'}healex.systems",
    website: "Website: https://healex.systems",
    section3: "3. Cookies",
    s3p1: "Cookies im Allgemeinen sind Datenpakete (Textdateien), welche über den Internetbrowser auf Ihrem Computersystem abgelegt bzw. gespeichert werden.",
    s3p2: "Healex Studget verwendet ausschließlich technisch notwendiges Cookies, zur Authentifizierung des Nutzers nach erfolgreicher Anmeldung, welches beim Schließen des Browsers bzw. bei Abmeldung gelöscht wird und somit keine Datenreste hinterlässt.",
    s3p3: "Das System verwendet Cookies in keinem anderen Falle.",
    s3p4: "Im Kontext der DSGVO ist das von uns eingesetzte Cookie nicht von der „Opt-In-Regelung“ betroffen, Sie müssen daher Cookies nicht aktiv zulassen, vgl. EU-Verordnung über Privatsphäre und elektronische Kommunikation, Art. 21.",
    section4: "4. Erfassung von personenbezogenen Daten und Informationen",
    s4p1d1: "Healex Studget beinhaltet ",
    s4p1d2: "keinerlei Patientendaten",
    s4p1d3:
      "sondern ausschließlich organisatorische Daten, die im Zusammenhang mit der Kalkulation klinischer Studien erhoben werden.",
    s4p1d4: "",
    s4p1d5: "",
    s4p2d1: "Konkret werden daher nur folgende Merkmale eines ",
    s4p2d2: "Nutzers",
    s4p2d3: " erfasst:",
    s4p3item1: "Vor- & Nachname",
    s4p3item2: "E-Mail-Adresse",
    s4p3item3: "Benutzername",
    s4p3item4: "Benutzer-ID",
    s4p3item5: "Healex ClinicalSite Konto",
    s4p4: "Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die Healex GmbH keine Rückschlüsse auf die betroffene Person.",
    s4subtitle1: "Rechte der betroffenen Person",
    s4st1p1:
      "Healex Studget nimmt die Verantwortung zur Verwahrung personenbezogenen Daten seiner Kundinnen und Kunden sehr ernst. Zur Erbringung der mit Healex vereinbarten Leistung verarbeiten beide Akteure personenbezogene Daten. Die in den folgenden Unterkapitel dargestellten Betroffenenrechte gewährleisten den rechtskonformen Umgang mit den im System gespeicherter Personendaten.",
    s4subtitle2:
      "Rechtmäßigkeit, Verarbeitung nach Treue, Glauben, Transparenz (Kap. 2 Art. 5 (1) a))",
    s4st2p1:
      "Bei Healex gilt stets der Grundsatz, dass ein Schaden für Kundinnen und Kunden so gut wie möglich ausgeschlossen werden muss, d. h. der dezidierte Nutzer bekommt bei Healex Studget die von unserer Seite aus bestmöglich zu erbringende Qualität. Daher werden nur Daten gespeichert, die aus technischen und/oder regulatorischen Gründen unbedingt erforderlich sind.",
    s4st2p2d1: "Zu keinem Zeitpunkt",
    s4st2p2d2:
      " verarbeitet Healex Studget Daten zur „Performance-Messung“, „User-Experience“, „Tracking“, „Profiling“ oder Auswertung sonstiger Internetmarketing-technischen KPIs.",
    s4subtitle3: "Datenminimierung (Kap. 2 Art. 5 (1) c))",
    s4st3p1:
      "Das Healex Studget verarbeitet ausschließlich die zur Verwaltung von Kostenkalkulationen klinischer Studien notwendigen personenbezogenen Daten. Personenbezogen Daten außerhalb dieses Kontextes werden zu keinem Zeitpunkt erhoben, gespeichert oder exportiert (verarbeitet).",
    s4subtitle4:
      "Informationspflicht bei Erhebung von personenbezogenen Daten (Art. 13 DSGVO)",
    s4st4p1:
      "Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden. Möchte eine betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter, über die o. g. E-Mail-Adresse, des für die Verarbeitung Verantwortlichen wenden.",
    s4subtitle5: "Auskunftsrecht der betroffenen Person (Art. 15 DSGVO)",
    s4st5p1:
      "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von dem für die Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu seiner Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten. Ferner hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über folgende Informationen zugestanden:",
    s4st5p2item1: "die Verarbeitungszwecke",
    s4st5p2item2:
      "die Kategorien personenbezogener Daten, die verarbeitet werden",
    s4st5p2item3:
      "die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen",
    s4st5p2item4:
      "falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer",
    s4st5p2item5:
      "das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung",
    s4st5p2item6:
      "das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde",
    s4st5p2item7:
      "wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen über die Herkunft der Daten",
    s4st5p2item8:
      "das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen Fällen — aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person",
    s4st5p3:
      "Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen Person im Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.",
    s4st5p4:
      "Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.",
    s4subtitle6: "Recht auf Berichtigung (Art. 16 DSGVO)",
    s4st6p1:
      "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten — auch mittels einer ergänzenden Erklärung — zu verlangen.",
    s4st6p2:
      "Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.",
    s4st6p3:
      "Die betroffene Person kann die Berichtigung, nach erfolgreicher Anmeldung, auch innerhalb des dezidierten Bereichs selbst vornehmen.",
    s4subtitle7:
      'Recht auf Löschung "Recht auf Vergessenwerden" (Art. 17 DSGVO)',
    s4st7p1:
      "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu verlangen, dass die sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung nicht erforderlich ist:",
    s4st7p2item1:
      "Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise verarbeitet, für welche sie nicht mehr notwendig sind.",
    s4st7p2item2:
      "Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.",
    s4st7p2item3:
      "Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.",
    s4st7p2item4:
      "Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.",
    s4st7p2item5:
      "Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.",
    s4st7p2item6:
      "Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.",
    s4st7p3:
      "Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung von personenbezogenen Daten, die bei der Healex GmbH gespeichert sind, veranlassen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der Healex GmbH wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.",
    s4st7p4:
      "Wurden die personenbezogenen Daten von der Healex GmbH öffentlich gemacht und ist unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur Löschung der personenbezogenen Daten verpflichtet, so trifft die Healex GmbH unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung Verantwortliche, welche die veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene Person von diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht erforderlich ist. Der Mitarbeiter der Healex GmbH wird im Einzelfall das Notwendige veranlassen.",
    s4subtitle8: "Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)",
    s4st8p1:
      "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:",
    s4st8p2item1:
      "Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen.",
    s4st8p2item2:
      "Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen Daten.",
    s4st8p2item3:
      "Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.",
    s4st8p2item4:
      "Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DS-GVO eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen.",
    s4st8p3:
      "Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person die Einschränkung von personenbezogenen Daten, die bei der Healex GmbH gespeichert sind, verlangen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der Healex GmbH wird die Einschränkung der Verarbeitung veranlassen.",
    s4subtitle9: "Recht auf Datenübertragbarkeit (Art. 20 DSGVO)",
    s4st9p1:
      "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die sie betreffenden personenbezogenen Daten, welche durch die betroffene Person einem Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie hat außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.",
    s4st9p2:
      "Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.",
    s4st9p3:
      "Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person jederzeit an einen Mitarbeiter der Healex GmbH wenden.",
    s4subtitle10: "Widerspruchsrecht (Art. 21 DSGVO)",
    s4st10p1:
      "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.",
    s4st10p2:
      "Die Healex GmbH verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.",
    s4st10p3:
      "Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt an jeden Mitarbeiter der Healex GmbH oder einen anderen Mitarbeiter wenden. Der betroffenen Person steht es ferner frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.",
    section5: "5. Rechtsgrundlage der Verarbeitung",
    s5p1: "Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine von Leistungen oder Gegenleistungen notwendig sind, so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind. Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO.",
    section6:
      "6. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der personenbezogenen Daten; Verpflichtung der betroffenen Person, die personenbezogenen Daten bereitzustellen;",
    s6p1: "Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus vertraglichen Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann.",
    s6p2: "Mitunter kann es zu einem Vertragsschluss erforderlich sein, dass eine betroffene Person uns personenbezogene Daten zur Verfügung stellt, die in der Folge durch uns verarbeitet werden müssen. Die betroffene Person ist beispielsweise verpflichtet uns personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag abschließt. Eine Nichtbereitstellung der personenbezogenen Daten hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht geschlossen werden könnte.",
    s6p3: "Vor einer Bereitstellung personenbezogener Daten durch den Betroffenen muss sich der Betroffene an einen unserer Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die personenbezogenen Daten bereitzustellen, und welche Folgen die Nichtbereitstellung der personenbezogenen Daten hätte.",
  },
};
