import axios from "@/services/api";

const state = {
  personalCosts: [],
  materialCosts: [],
};

const getters = {
  personalCosts: (state) => state.personalCosts,
  materialCosts: (state) => state.materialCosts,
};

const actions = {
  getPersonalCosts({ commit }, payload) {
    let { lStatus, statusStartDate, statusEndDate } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`cases/personal-costs`, {
          lStatus,
          statusStartDate,
          statusEndDate,
        })
        .then(
          (response) => {
            commit("setPersonalCosts", response.data);
            resolve(response.data);
          },
          (error) => {
            commit("setPersonalCosts", []);
            reject(error.response.data);
          },
        );
    });
  },
  getDownloadPersonalCosts(_, payload) {
    let { lStatus, statusStartDate, statusEndDate } = payload;
    return new Promise((resolve, reject) => {
      axios({
        url: `cases/cost-report/export`,
        data: {
          lStatus,
          statusStartDate,
          statusEndDate,
        },
        method: "POST",
        responseType: "blob",
      }).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
  getMaterialCosts({ commit }, payload) {
    let { lStatus, statusStartDate, statusEndDate } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`cases/material-costs`, {
          lStatus,
          statusStartDate,
          statusEndDate,
        })
        .then(
          (response) => {
            commit("setMaterialCosts", response.data);
            resolve(response.data);
          },
          (error) => {
            commit("setMaterialCosts", []);
            reject(error.response.data);
          },
        );
    });
  },
};

const mutations = {
  setPersonalCosts(state, personalCosts) {
    state.personalCosts = personalCosts;
  },
  setMaterialCosts(state, materialCosts) {
    state.materialCosts = materialCosts;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
