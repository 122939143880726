import axios from "@/services/api";

const defaultCaseTemplate = {
  idCaseTemplate: 0,
  caseTemplateName: "",
  idOrganizationalUnit: 0,
  organizationalUnitName: "",
  rows: [],
  parameters: [],
};

const state = {
  caseTemplate: defaultCaseTemplate,
  caseTemplateGeneralSummations: [],
  caseTemplateFields: [],
  masterForms: [],
  parameters: [],
  generalSummations: [],
};

const getters = {
  caseTemplate: (state) => state.caseTemplate,
  masterForms: (state) => state.masterForms,
  parameters: (state) => state.parameters,
  caseTemplateGeneralSummations: (state) => state.caseTemplateGeneralSummations,
  caseTemplateFields: (state) => state.caseTemplateFields,
  generalSummations: (state) => state.generalSummations,
};

const actions = {
  getCaseTemplateGeneralSummations({ commit }, payload) {
    let { api, idCaseTemplate } = payload;
    return new Promise((resolve, reject) => {
      api.getCaseTemplateGeneralSummations(idCaseTemplate).then(
        (response) => {
          commit("setCaseTemplateGeneralSummations", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  getCaseTemplateFields({ commit }, payload) {
    let { idCaseTemplate } = payload;
    return new Promise((resolve, reject) => {
      axios.get(`/case-templates/${idCaseTemplate}/fields`).then(
        (response) => {
          commit("setCaseTemplateFields", response.data);
          resolve(response);
        },
        (error) => {
          commit("setCaseTemplateFields", []);
          reject(error);
        },
      );
    });
  },
  postCaseTemplateField({ commit }, payload) {
    let {
      idCaseTemplate,
      fieldTitle,
      fieldValue,
      restricted,
      mandatory,
      reviewerRequired,
    } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`case-templates/${idCaseTemplate}/fields`, {
          fieldTitle,
          fieldValue,
          restricted,
          mandatory,
          reviewerRequired,
        })
        .then(
          (response) => {
            commit("appendCaseField", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  putCaseTemplateField({ commit }, payload) {
    let {
      idCaseTemplateField,
      fieldTitle,
      fieldValue,
      restricted,
      mandatory,
      reviewerRequired,
    } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`case-templates/fields/${idCaseTemplateField}`, {
          fieldTitle,
          fieldValue,
          restricted,
          mandatory,
          reviewerRequired,
        })
        .then(
          (response) => {
            commit("setCaseField", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  putCaseTemplateFieldName({ commit }, payload) {
    let { idCaseTemplate, defaultName } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`case-templates/${idCaseTemplate}/default-name`, {
          defaultName,
        })
        .then(
          (response) => {
            commit("setCaseFieldName", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  putCaseTemplateFieldDuration({ commit }, payload) {
    let { idCaseTemplate, duration } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`case-templates/${idCaseTemplate}/duration`, {
          duration,
        })
        .then(
          (response) => {
            commit("setCaseFieldDuration", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  putCaseTemplateFieldStudytype({ commit }, payload) {
    let { idCaseTemplate, idStudyType } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`case-templates/${idCaseTemplate}/study-type`, {
          idStudyType,
        })
        .then(
          (response) => {
            commit("setCaseFieldStudytype", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  putMoveUpField({ commit }, payload) {
    let { idCaseTemplateField, idFieldToChangePosition } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(
          `case-templates/fields/${idCaseTemplateField}/position?idFieldToChangePosition=${idFieldToChangePosition}`,
        )
        .then(
          (response) => {
            commit("changeUpField", response.data);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  putMoveDownField({ commit }, payload) {
    let { idCaseTemplateField, idFieldToChangePosition } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(
          `case-templates/fields/${idCaseTemplateField}/position?idFieldToChangePosition=${idFieldToChangePosition}`,
        )
        .then(
          (response) => {
            commit("changeDownField", response.data);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  getGeneralSummations({ commit }, payload) {
    let { api } = payload;
    return new Promise((resolve, reject) => {
      api.getAllGeneralSummations().then(
        (response) => {
          commit("setGeneralSummations", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  createGeneralSummationCaseTemplate({ commit }, payload) {
    const { api, idCaseTemplate, idGeneralSummation } = payload;

    return new Promise((resolve, reject) => {
      api
        .createGeneralSummationCaseTemplate({
          idCaseTemplate,
          idGeneralSummation,
        })
        .then(
          (response) => {
            commit("setGeneralSummation", response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  updateGeneralSummations({ commit }, payload) {
    const {
      api,
      idCaseTemplate,
      idGeneralSummation,
      showSubcases,
      showBudget,
    } = payload;

    return new Promise((resolve, reject) => {
      api
        .updateGeneralSummations(
          idCaseTemplate,
          idGeneralSummation,
          showSubcases,
          showBudget,
        )
        .then(
          (response) => {
            resolve(response);
            commit("updateGeneralSummations", response.data);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },

  deleteGeneralSummations({ commit }, payload) {
    const { api, idCaseTemplate, idGeneralSummation } = payload;

    return new Promise((resolve, reject) => {
      api.deleteGeneralSummations(idCaseTemplate, idGeneralSummation).then(
        (response) => {
          resolve(response);
          commit("deleteGeneralSummations", {
            idCaseTemplate,
            idGeneralSummation,
          });
        },
        (error) => {
          reject(error);
        },
      );
    });
  },

  getCaseTemplate({ commit }, payload) {
    let { api, idCaseTemplate } = payload;

    return new Promise((resolve, reject) => {
      api.getCaseTemplate(idCaseTemplate).then(
        (response) => {
          commit("setCaseTemplate", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Error on case template get: ", error);
          commit("setCaseTemplate", defaultCaseTemplate);
          reject(error);
        },
      );
    });
  },
  addRow({ commit }, payload) {
    const { api, idCaseTemplate, rowName, idMasterForm, blocked, index } =
      payload;

    return new Promise((resolve, reject) => {
      api
        .postCaseTemplateRow(idCaseTemplate, {
          rowName,
          idMasterForm,
          blocked,
          index,
        })
        .then(
          (response) => {
            commit("addRow", response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  deleteRow({ commit }, payload) {
    const { api, idCaseTemplate, idTemplateRow } = payload;

    return new Promise((resolve, reject) => {
      api.deleteCaseTemplateRow(idCaseTemplate, idTemplateRow).then(
        (response) => {
          commit("deleteRow", idTemplateRow);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  updateRow({ commit }, payload) {
    const {
      api,
      idCaseTemplate,
      idCaseTemplateRow,
      name,
      index,
      idMasterForm,
      blocked,
    } = payload;
    return new Promise((resolve, reject) => {
      api
        .updateCaseTemplateRow(idCaseTemplate, idCaseTemplateRow, {
          name,
          index,
          idMasterForm,
          blocked,
        })
        .then(
          (response) => {
            commit("updateRow", response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  getMasterForms({ commit }, payload) {
    let { idOrganizationalUnit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/master-forms/organizational-unit/${idOrganizationalUnit}`)
        .then(
          (response) => {
            commit("setMasterForms", response.data);
            resolve(response);
          },
          (error) => {
            console.log("Master Form error: ", error);
            commit("setMasterForms", []);
            reject(error);
          },
        );
    });
  },
  getParameters({ commit }, payload) {
    let { idOrganizationalUnit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/parameters/organizational-unit/${idOrganizationalUnit}?enabled=true`,
        )
        .then(
          (response) => {
            commit("setParameters", response.data);
            resolve(response);
          },
          (error) => {
            console.log("Get parameters error: ", error);
            commit("setParameters", []);
            reject(error);
          },
        );
    });
  },
  addParameter({ commit }, payload) {
    const { idCaseTemplate, idParameter, value } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(`/parameters/case-template`, {
          idCaseTemplate: idCaseTemplate,
          idParameter: idParameter,
          defaultValue: value,
        })
        .then(
          (response) => {
            commit("addParameter", response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  deleteParameter({ commit }, payload) {
    const { idCaseTemplate, idParameter } = payload;

    return new Promise((resolve, reject) => {
      axios
        .delete(`/parameters/${idParameter}/case-template/${idCaseTemplate}`)
        .then(
          (response) => {
            commit("deleteParameter", idParameter);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  deleteCaseTemplateField({ commit }, payload) {
    let { idCaseTemplateField } = payload;

    return new Promise((resolve, reject) => {
      axios.delete(`/case-templates/fields/${idCaseTemplateField}`).then(
        (response) => {
          commit("eraseCaseTemplateField", idCaseTemplateField);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
  updateParameter({ commit }, payload) {
    const { idCaseTemplate, idParameter, defaultValue } = payload;

    return new Promise((resolve, reject) => {
      axios
        .put(`/parameters/${idParameter}/case-template/${idCaseTemplate}`, {
          value: defaultValue,
        })
        .then(
          (response) => {
            commit("updateParameter", response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  rename({ commit }, payload) {
    const { idCaseTemplate, caseTemplateName } = payload;

    return new Promise((resolve, reject) => {
      axios
        .put(`/case-templates/rename/${idCaseTemplate}`, {
          caseTemplateName,
        })
        .then(
          (response) => {
            commit("rename", caseTemplateName);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
};
const mutations = {
  setCaseTemplate: (state, caseTemplate) => {
    state.caseTemplate = caseTemplate;
  },
  setCaseTemplateGeneralSummations: (state, caseTemplateGeneralSummations) => {
    state.caseTemplateGeneralSummations = caseTemplateGeneralSummations;
  },
  setCaseTemplateFields: (state, caseTemplateFields) => {
    state.caseTemplateFields = caseTemplateFields;
  },
  appendCaseField(state, newCaseField) {
    state.caseTemplateFields.fieldsList.push(newCaseField);
  },
  setCaseField(state, caseTemplateField) {
    const index = state.caseTemplateFields.fieldsList.findIndex(
      (x) => x.idCaseTemplateField === caseTemplateField.idCaseTemplateField,
    );
    state.caseTemplateFields.fieldsList[index].fieldTitle =
      caseTemplateField.fieldTitle;
    state.caseTemplateFields.fieldsList[index].fieldValue =
      caseTemplateField.fieldValue;
    state.caseTemplateFields.fieldsList[index].restricted =
      caseTemplateField.restricted;
    state.caseTemplateFields.fieldsList[index].mandatory =
      caseTemplateField.mandatory;
    state.caseTemplateFields.fieldsList[index].reviewerRequired =
      caseTemplateField.reviewerRequired;
  },
  setCaseFieldName(state, caseTemplateField) {
    state.caseTemplateFields.caseDefaultName = caseTemplateField.defaultName;
  },
  setCaseFieldDuration(state, caseTemplateField) {
    state.caseTemplateFields.duration = caseTemplateField.duration;
  },
  setCaseFieldStudytype(state, caseTemplateField) {
    state.caseTemplateFields.studyTypeName = caseTemplateField.studyTypeName;
    state.caseTemplateFields.studyType = caseTemplateField.idStudyType;
  },
  changeUpField(state, caseTemplateField) {
    const index = state.caseTemplateFields.fieldsList.indexOf(
      (x) => x.idCaseTemplateField === caseTemplateField.idCaseTemplateField,
    );
    if (index > 0) {
      state.caseTemplateFields.fieldsList.splice(
        index - 1,
        0,
        state.caseTemplateFields.fieldsList.splice(index, 1)[0],
      );
    }
  },
  changeDownField(state, caseTemplateField) {
    const index = state.caseTemplateFields.fieldsList.indexOf(
      (x) => x.idCaseTemplateField === caseTemplateField.idCaseTemplateField,
    );
    if (index < state.caseTemplateFields.fieldsList.length - 1) {
      state.caseTemplateFields.fieldsList.splice(
        index + 1,
        0,
        state.caseTemplateFields.fieldsList.splice(index, 1)[0],
      );
    }
  },
  setGeneralSummations: (state, generalSummations) => {
    state.generalSummations = generalSummations;
  },
  setGeneralSummation: (state, generalSummations) => {
    state.caseTemplateGeneralSummations.push(generalSummations);
  },
  addRow: (state, row) => {
    state.caseTemplate.rows.push(row);
  },
  deleteRow: (state, idTemplateRow) => {
    state.caseTemplate.rows = state.caseTemplate.rows.filter(function (obj) {
      return obj.idTemplateRow !== idTemplateRow;
    });
  },
  updateRow: (state, row) => {
    var oldRow = state.caseTemplate.rows.find(
      (x) => x.idTemplateRow == row.idTemplateRow,
    );
    oldRow.index = row.index;
    oldRow.blocked = row.blocked;
    oldRow.rowName = row.rowName;
    oldRow.masterFormName = row.masterFormName;
    oldRow.idMasterForm = row.idMasterForm;
  },
  updateGeneralSummations: (state, row) => {
    state.caseTemplateGeneralSummations.forEach((element) => {
      if (
        element.idCaseTemplate === row.idCaseTemplate &&
        element.idGeneralSummation === row.idGeneralSummation
      ) {
        element = row;
      }
    });
  },
  deleteGeneralSummations: (state, row) => {
    if (!Array.isArray(state.caseTemplateGeneralSummations)) return;
    let index;
    state.caseTemplateGeneralSummations.forEach((element, i) => {
      if (
        element.idCaseTemplate === row.idCaseTemplate &&
        element.idGeneralSummation === row.idGeneralSummation
      ) {
        index = i;
      }
    });
    state.caseTemplateGeneralSummations.splice(index, 1);
  },
  setMasterForms: (state, masterForms) => {
    state.masterForms = masterForms;
  },
  setParameters: (state, parameters) => {
    state.parameters = parameters;
  },
  addParameter: (state, parameter) => {
    state.caseTemplate.parameters.push(parameter);
  },
  deleteParameter: (state, idParameter) => {
    state.caseTemplate.parameters = state.caseTemplate.parameters.filter(
      function (obj) {
        return obj.idParameter !== idParameter;
      },
    );
  },
  eraseCaseTemplateField: (state, idCaseTemplateField) => {
    const index = state.caseTemplateFields.fieldsList.findIndex(
      (x) => x.idCaseTemplateField == idCaseTemplateField,
    );
    if (index != -1) {
      state.caseTemplateFields.fieldsList.splice(index, 1);
    }
  },
  updateParameter: (state, parameter) => {
    var oldParameter = state.caseTemplate.parameters.find(
      (x) => x.idParameter == parameter.idParameter,
    );
    oldParameter.defaultValue = parameter.defaultValue;
  },
  rename: (state, name) => {
    state.caseTemplate.caseTemplateName = name;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
