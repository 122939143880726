//import axios from 'axios';

const state = {
  caseInfo: {},
  template: {},
  salaryList: [],
  caseId: -1,
  loading: false,
  caseState: 0,
  currentEditor: null,
  studyTypes: [],
  surcharges: [],
  surchargeTypes: [],
};

const getters = {
  caseFile: (state) => state.caseInfo,
  templateFile: (state) => state.template,
  summaryFile: (state) => state.summaryFile,
  SalaryList: (state) => state.salaryList,
  IDCase: (state) => state.caseId,
  loading: (state) => state.loading,
  State: (state) => state.caseState,
  Editor: (state) => state.currentEditor,
  StudyTypes: (state) => state.studyTypes,
  Surcharges: (state) => state.surcharges,
  SurchargeTypes: (state) => state.surchargeTypes,
};

const actions = {
  async getCase({ commit }, payload) {
    let { api, idOrganizationalUnit, idCase } = payload;
    commit("request");
    await api
      .getCaseInfo(idOrganizationalUnit, idCase)
      .then((response) => {
        // handle success
        let file = JSON.parse(response.data.file);
        commit("setcase", file);
        commit("setState", response.data.idStatus);
        commit("setEditor", response.data.currentEditor);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async getTemplate({ commit }, payload) {
    let { api, idOrganizationalUnit } = payload;
    commit("request");
    await api
      .getMasterForm(idOrganizationalUnit)
      .then((response) => {
        // handle success
        let file = {
          general: JSON.parse(response.data.general),
          specific: JSON.parse(response.data.specific),
        };
        let summaryFile = JSON.parse(response.data.summaryFile);
        commit("settemplate", file);
        commit("setSummaryFile", summaryFile);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async UpdateCase({ commit }, payload) {
    let { api, idOrganizationalUnit, idCase, file } = payload;
    commit("request");

    return new Promise((resolve, reject) => {
      api.updateCaseFile(idOrganizationalUnit, idCase, file).then(
        (response) => {
          if (response.status === 200) {
            commit("setcase", file);
            resolve(response);
          } else {
            reject(response.data.message);
          }
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  async UpdateTemplate({ commit }, payload) {
    let { api, idOrganizationalUnit, file, summaryFile } = payload;
    commit("request");
    await api
      .UpdateTemplate(idOrganizationalUnit, file, summaryFile)
      .then(() => {
        // handle success
        commit("settemplate", file);
        commit("setSummaryFile", summaryFile);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async NewSubcase({ commit }, payload) {
    let { api, idOrganizationalUnit, file, caseName, idCase } = payload;
    let userName = localStorage.getItem("healex-userName");
    file.UserName = userName;

    commit("request");
    await api
      .saveNewCase(caseName, JSON.stringify(file), idOrganizationalUnit, idCase)
      .then((response) => {
        // handle success
        commit("setIdCase", response);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async getSalaryTypes({ commit }, payload) {
    let { api } = payload;
    commit("request");
    await api
      .getSalaryTypes()
      .then((response) => {
        // handle success
        commit("setSalaryList", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async getSalaryInfo({ commit }, payload) {
    let { api, idSalary, index } = payload;
    commit("request");
    await api
      .getSalaries(idSalary)
      .then((response) => {
        // handle success
        commit("setSalaryInfo", { data: response.data, index: index });
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async getSubcaseState({ commit }, payload) {
    let { api, idSubcase } = payload;
    commit("request");
    await api
      .getSubcaseState(idSubcase)
      .then((response) => {
        // handle success
        commit("setState", response.data.status);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        commit("requested");
      });
  },
  async getStudyTypes({ commit }, payload) {
    let { api, enabled } = payload;

    await api
      .GetStudyTypes(enabled)
      .then((response) => {
        // handle success
        commit("setStudyTypes", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  },
  async getSurcharges({ commit }, payload) {
    let { api, idCondition } = payload;

    await api
      .GetSurcharges(idCondition)
      .then((response) => {
        // handle success
        commit("setSurcharges", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  },
  async getSurchargeTypes({ commit }, payload) {
    let { api } = payload;

    await api
      .GetSurchargesTypes(true)
      .then((response) => {
        // handle success
        commit("setSurchargeTypes", response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  },
};

const mutations = {
  setcase: (state, caseInfo) => (state.caseInfo = caseInfo),
  settemplate: (state, caseInfo) => (state.template = caseInfo),
  setSummaryFile: (state, caseSummary) => {
    const convertArrayToObject = (array, key) => {
      const initialValue = {};
      return array.reduce((obj, item) => {
        return {
          ...obj,
          [item[key]]: item,
        };
      }, initialValue);
    };
    state.summaryFile = convertArrayToObject(caseSummary, "Id");
  },
  setState: (state, caseState) => (state.caseState = caseState),
  setEditor: (state, editor) => (state.currentEditor = editor),
  setIdCase: (state, caseId) => (state.caseId = caseId),
  setSalaryList: (state, list) => (state.salaryList = list),
  setSalaryInfo: (state, info) =>
    (state.salaryList[info.index].Salaries = info.data),
  updatecase: (state, caseFile) => (state.caseInfo = caseFile),
  request: (state) => {
    state.loading = true;
  },
  requested: (state) => {
    state.loading = false;
  },
  setStudyTypes: (state, data) => {
    state.studyTypes = data;
  },
  setSurcharges: (state, data) => {
    state.surcharges = data;
  },
  setSurchargeTypes: (state, data) => {
    state.surchargeTypes = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
