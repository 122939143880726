import axios from "@/services/api";

const state = {
  conditions: [],
  surcharges: [],
};

const getters = {
  conditions: (state) => state.conditions,
  surchargeTypes: (state) => state.surcharges,
};

const actions = {
  getConditions({ commit }, payload) {
    let { enabled } = payload;
    return new Promise((resolve, reject) => {
      axios.get(`/Customer/Surcharge/Conditions?enabled=${enabled}`).then(
        (response) => {
          commit("setConditions", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Condition Response: ", error);
          commit("setConditions", []);
          reject(error);
        },
      );
    });
  },
  AddCondition({ commit }, payload) {
    const { sName } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`/Customer/Surcharge/Conditions`, {
          Name: sName,
        })
        .then(
          (response) => {
            commit("addCondition", {
              idCondition: response.data.idEntity,
              sumlageName: sName,
              enabled: true,
            });
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  DeleteCondition({ commit }, payload) {
    const { idCondition } = payload;
    return new Promise((resolve, reject) => {
      axios.delete(`/Customer/Surcharge/Conditions/${idCondition}`).then(
        (response) => {
          commit("deleteCondition", idCondition);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  UpdateCondition({ commit }, payload) {
    const { idCondition, sName } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/Customer/Surcharge/Conditions/${idCondition}`, {
          Name: sName,
        })
        .then(
          (response) => {
            commit("updateCondition");
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  ActivateCondition({ commit }, payload) {
    const { idCondition } = payload;
    return new Promise((resolve, reject) => {
      axios.put(`/Customer/Surcharge/Conditions/${idCondition}/Activate`).then(
        (response) => {
          commit("deleteCondition", idCondition);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  GetSurchargeTypes({ commit }, payload) {
    let { idCondition } = payload;

    return new Promise((resolve, reject) => {
      axios.get(`/Customer/Surcharge/Condition/${idCondition}`).then(
        (response) => {
          commit("setSurchargeTypes", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Condition Response: ", error);
          commit("setConditions", []);
          reject(error);
        },
      );
    });
  },
  UpdateSurcharges({ commit }, payload) {
    const { api, idCondition, values } = payload;
    return new Promise((resolve, reject) => {
      api.UpdateConditionValues(idCondition, values).then(
        (response) => {
          commit("updateCondition");
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};
const mutations = {
  setConditions: (state, conditions) => {
    state.conditions = conditions;
  },
  addCondition: (state, condition) => {
    state.conditions.push(condition);
  },
  deleteCondition: (state, idCondition) => {
    state.conditions = state.conditions.filter(function (obj) {
      return obj.idCondition !== idCondition;
    });
  },
  updateCondition: () => {},
  setSurchargeTypes: (state, surcharges) => {
    state.surcharges = surcharges;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
