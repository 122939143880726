import axios from "@/services/api";

const state = {
  users: [],
  user: null,
  roles: [],
  Customers: [],
  OrganizationalUnits: [],
  AvailableRoles: [],
  userProfile: null,
};
const getters = {
  users: (state) => state.users,
  user: (state) => state.user,
  roles: (state) => state.roles,
  Customers: (state) => state.Customers,
  OrganizationalUnits: (state) => state.OrganizationalUnits,
  AvailableRoles: (state) => state.AvailableRoles,
  userProfile: (state) => state.userProfile,
};
const actions = {
  getUser({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.GetUser(payload).then(
        (response) => {
          commit("setUser", response.data);
          resolve(response.data);
        },
        (error) => {
          commit("setUser", null);
          reject(error.response.data.message);
        },
      );
    });
  },
  getUsers({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.GetUsers(payload).then(
        (response) => {
          commit("setUsers", response.data);
          resolve(response.data);
        },
        (error) => {
          commit("setUsers", []);
          reject(error.response.data.message);
        },
      );
    });
  },
  createUser({ commit }, payload) {
    const { api, user } = payload;
    return new Promise((resolve, reject) => {
      api.CreateUser(payload).then(
        (response) => {
          commit("setUser", user);
          resolve(response.data.message);
        },
        (error) => {
          reject(error.response.data.message);
        },
      );
    });
  },
  updateUser({ commit }, payload) {
    const { api, user } = payload;
    return new Promise((resolve, reject) => {
      api.UpdateUser(payload).then(
        (response) => {
          commit("setUser", user);
          resolve(response.data.message);
        },
        (error) => {
          reject(error.response.data.message);
        },
      );
    });
  },
  enable({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.ActiveUser(payload).then(
        (response) => {
          commit("removeUser", payload.userName);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.message);
        },
      );
    });
  },
  disenable({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.DeleteUser(payload).then(
        (response) => {
          commit("removeUser", payload.userName);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.message);
        },
      );
    });
  },
  deletePermanentlyUser({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.DeletePermanentlyUser(payload).then(
        (response) => {
          commit("removeUser", payload.userName);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.message);
        },
      );
    });
  },
  getUserCases() {
    const payload = arguments[1];
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.VerifyUserCases(payload).then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error.response.data.message);
        },
      );
    });
  },
  resetPassword() {
    const payload = arguments[1];
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.ResetPassword(payload).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error.message);
        },
      );
    });
  },
  seflResetPassword() {
    const payload = arguments[1];
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.SelfResetPassword().then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error.message);
        },
      );
    });
  },
  getRoles({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.UserRoles(payload).then(
        (response) => {
          commit("setRoles", response.data);
          resolve(response);
        },
        (error) => {
          commit("setRoles", []);
          reject(error.response.data.message);
        },
      );
    });
  },

  addRole({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.AddRole(payload).then(
        (response) => {
          commit("addRole", payload);
          resolve(response.data.message);
        },
        (error) => {
          reject(error.response.data.message);
        },
      );
    });
  },

  removeRole({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.RemoveRole(payload).then(
        (response) => {
          commit("removeRole", payload);
          resolve(response.data.message);
        },
        (error) => {
          reject(error.response.data.message);
        },
      );
    });
  },

  getCustomers({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.GetCustomers(payload).then(
        (response) => {
          commit("setCustomers", response.data);
          resolve(response.data);
        },
        (error) => {
          commit("setCustomers", []);
          reject(error.response.data.message);
        },
      );
    });
  },
  getOrganizationalUnits({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.GetOrganizationalUnits(payload).then(
        (response) => {
          commit("setOrganizationalUnits", response.data);
          resolve(response.data);
        },
        (error) => {
          commit("setOrganizationalUnits", []);
          reject(error.response.data.message);
        },
      );
    });
  },
  getAvailableRoles({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.getAvailableRoles(payload).then(
        (response) => {
          commit("setAvailableRoles", response.data);
          resolve(response);
        },
        (error) => {
          commit("setAvailableRoles", []);
          reject(error.response.data.message);
        },
      );
    });
  },
  getUserProfile({ commit }, payload) {
    const { api } = payload;
    return new Promise((resolve, reject) => {
      api.GetUser(payload).then(
        (response) => {
          commit("setUserProfile", response.data);
          resolve(response.data);
        },
        (error) => {
          commit("setUserProfile", null);
          reject(error.response.data.message);
        },
      );
    });
  },
  putPasswordRecovery({ commit }, payload) {
    let { userMail } = payload;
    return new Promise((resolve, reject) => {
      axios.put(`/User/RecoveryPassword?userMail=${userMail}`).then(
        (response) => {
          resolve(response);
          commit();
        },
        (error) => {
          reject(error.response);
        },
      );
    });
  },
};
const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  setUser(state, user) {
    state.user = user;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setCustomers(state, Customers) {
    state.Customers = Customers;
  },
  setOrganizationalUnits(state, OrganizationalUnits) {
    state.OrganizationalUnits = OrganizationalUnits;
  },
  setAvailableRoles(state, AvailableRoles) {
    state.AvailableRoles = AvailableRoles;
  },
  removeUser: (state, userName) => {
    state.users = state.users.filter(function (obj) {
      return obj.userName !== userName;
    });
  },
  addRole: (state, { idOU, nameOrganizationalUnit, nameRole }) => {
    state.roles.push({
      idOrganizationalunit: idOU,
      nameOrganizationalUnit: nameOrganizationalUnit,
      roleName: nameRole,
    });
  },
  removeRole: (state, { idOU, nameRole }) => {
    state.roles = state.roles.filter(
      (item) =>
        !(item.idOrganizationalunit === idOU && item.roleName === nameRole),
    );
  },
  setUserProfile: (state, payload) => {
    state.userProfile = payload;
  },
  //User Profile Data
  updateUserProfileName: (state, payload) => {
    state.userProfile.name = payload;
  },
  updateUserProfileMail: (state, payload) => {
    state.userProfile.mail = payload;
  },
  updateUserProfileIdCustomer: (state, payload) => {
    state.userProfile.mail = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
