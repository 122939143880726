<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  created() {
    this.getStatus({ api: this.$api });
  },
  methods: {
    ...mapActions({
      getStatus: "GET_STATUS",
    }),
  },
};
</script>

<style>
body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: #f9f8f8;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
