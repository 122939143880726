import axios from "@/services/api";

const state = {
  versions: {},
};

const getters = {
  Versions: (state) => state.versions,
};

const actions = {
  getVersion({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/versions").then(
        (response) => {
          commit("setVersion", response.data);
          resolve(response);
        },
        (error) => {
          commit("setVersion", {});
          reject(error);
        },
      );
    });
  },
};

const mutations = {
  setVersion(state, Version) {
    state.versions = Version;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
