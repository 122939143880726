<template>
  <div class="login-view bg-healex-gradient">
    <b-container>
      <div class="box text-center">
        <div class="box text-center">
          <b-card class="inner p-2 p-md-5 shadow title-noFound">
            {{ $t("views.confirmationEmail.Title") }}
            <b-button @click="$router.push('/login')" variant="info">{{
              $t("views.confirmationEmail.goToLogin")
            }}</b-button>
          </b-card>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.login-view {
  position: relative;
  width: 100%;
  height: 100%;
  background: #dedede;
}

.login-view .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-view .box .inner {
  position: relative;
  width: 500px;
  max-width: 100%;
}

.login-view .helex-log {
  display: block;
  max-width: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}
.title-noFound {
  font-size: 2.5rem;
  font-weight: bold;
  color: #808080;
}
</style>
