import axios from "@/services/api";

const state = {
  surcharges: [],
};

const getters = {
  surcharges: (state) => state.surcharges,
};

const actions = {
  getSurcharges({ commit }, payload) {
    let { enabled } = payload;
    return new Promise((resolve, reject) => {
      axios.get(`/Customer/Surcharge/Definitions?enabled=${enabled}`).then(
        (response) => {
          commit("setSurcharges", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Surcharge Response: ", error);
          commit("setSurcharges", []);
          reject(error);
        },
      );
    });
  },
  AddSurcharge({ commit }, payload) {
    const { sName } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`/Customer/Surcharge/Definitions`, {
          Name: sName,
        })
        .then(
          (response) => {
            commit("addSurcharge", {
              idSurcharge: response.data.idEntity,
              surchargeName: sName,
              enabled: true,
            });
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  DeleteSurcharge({ commit }, payload) {
    const { idSurcharge } = payload;
    return new Promise((resolve, reject) => {
      axios.delete(`/Customer/Surcharge/Definition/${idSurcharge}`).then(
        (response) => {
          commit("deleteSurcharge", idSurcharge);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
  UpdateSurcharge({ commit }, payload) {
    const { idSurcharge, surchargeName } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/Customer/Surcharge/Definition/${idSurcharge}`, {
          Name: surchargeName,
        })
        .then(
          (response) => {
            commit("updateSurcharge");
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        );
    });
  },
  ActivateSurcharge({ commit }, payload) {
    const { idSurcharge } = payload;
    return new Promise((resolve, reject) => {
      axios.put(`/Customer/Surcharge/Definition/${idSurcharge}/Activate`).then(
        (response) => {
          commit("deleteSurcharge", idSurcharge);
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  },
};
const mutations = {
  setSurcharges: (state, surcharges) => {
    state.surcharges = surcharges;
  },
  addSurcharge: (state, surcharge) => {
    state.surcharges.push(surcharge);
  },
  deleteSurcharge: (state, idSurcharge) => {
    state.surcharges = state.surcharges.filter(function (obj) {
      return obj.idSurcharge !== idSurcharge;
    });
  },
  updateSurcharge: () => {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
