import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import VueCookies from "vue-cookies";

const cookiesPlugin = VueCookies.plugin;

const app = createApp({});
app.use(cookiesPlugin);

const supportedLocales = {
  en: "English",
  de: "Deutsch", // Corrected the typo in 'Deutsch'
};

const getBrowserLocale = function (options = {}) {
  const defaultOptions = { countryCodeOnly: false };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;
  if (!navigatorLocale) {
    return undefined;
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();
  return trimmedLocale;
};

const supportedLocalesInclude = function (locale) {
  return Object.keys(supportedLocales).includes(locale);
};

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    number: {
      style: "decimal",
    },
  },
  de: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    number: {
      style: "decimal",
    },
  },
};

function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

function getStartingLocale() {
  const locale = app.config.globalProperties.$locale;

  if (locale) {
    return locale;
  } else {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true });
    if (supportedLocalesInclude(browserLocale)) {
      return browserLocale;
    } else {
      return process.env.VUE_APP_I18N_LOCALE || "en";
    }
  }
}

const i18n = createI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  numberFormats,
});

app.use(i18n);

export default i18n;
